import React, { useEffect } from "react";
import { Box, Modal, Typography, IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";
import PDFViewer from "../../atoms/pdf-viewer/PDFViewer";
import { getPDFLink } from "../../../util/pdf-functions";
import { PDFViewer as ReactPDFViewer } from "@react-pdf/renderer";
import { ExpenditureReport } from "../../../pages/pos/customer/ExpenditureReport";

interface PdfViewModalProps {
  open: boolean;
  onClose: () => void;
  reportName: string | null;
  name: string;
  customerId: string;
  address: string;
  contactNumber: string;
  total: number;
  month: string;
}

const PdfViewModal: React.FC<PdfViewModalProps> = ({
  open,
  onClose,
  reportName: report,
  name,
  customerId,
  address,
  contactNumber,
  total,
  month,
}) => {
  const [PDFLink, setPDFLink] = React.useState<string | null>(null);

  useEffect(() => {
    getPDFLink().then((link) => {
      setPDFLink(link);
    });
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.07)",
          backdropFilter: "blur(0.04rem)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: pxToRem(1344),
          bgcolor: Colors.WHITE,
          p: 4,
          borderRadius: "0.71rem",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            color: Colors.MAIN_GRAY,
          }}
        >
          <CloseIcon sx={{ fontSize: "1.571rem" }} />
        </IconButton>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              sx={{ color: Colors.PRIMARY, fontSize: "1.85714285714rem" }}
            >
              {report || "Report"}
            </Typography>
            <Typography
              sx={{
                marginLeft: "7rem",
                color: Colors.PRIMARY,
                fontSize: "1.42857142857rem",
              }}
            >
              09/12/2023
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              sx={{
                backgroundColor: Colors.WHITE,
                border: `1px solid ${Colors.PRIMARY}`,
                borderRadius: "0.5rem",
                width: "4.28571428571rem",
                height: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.5rem",
              }}
            >
              <IconButton>
                <DownloadIcon
                  sx={{ fontSize: "2rem", color: Colors.PRIMARY }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                backgroundColor: Colors.WHITE,
                border: `1px solid ${Colors.PRIMARY}`,
                borderRadius: "0.5rem",
                width: "4.28571428571rem",
                height: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.5rem",
              }}
            >
              <IconButton>
                <PrintIcon sx={{ fontSize: "2rem", color: Colors.PRIMARY }} />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "1rem",
            width: "100%",
            height: "53.6428571429rem",
            bgcolor: Colors.BACKSHADE_GRAY,
            // background: "red",
            borderRadius: "0.5rem",
            overflow: "hidden",
          }}
        >
          {/* <iframe
            src={`${pdfLink}#toolbar=0&navpanes=0&scrollbar=0`}
            width="100%"
            height="100%"
            style={{ border: "none", display: "block" }}
            title="PDF View"
          /> */}
          <ReactPDFViewer width="100%" height="100%">
            <ExpenditureReport
              name={name}
              customerId={customerId}
              address={address}
              contactNumber={contactNumber}
              total={total}
              month={month}
            />
          </ReactPDFViewer>
        </Box>
      </Box>
    </Modal>
  );
};

export default PdfViewModal;
