import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { Input } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import IOSSwitch from "../../../components/atoms/custom-toggle-button/custom-toggle-button";
import { PosLayout } from "../../../components";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import CustomDateTimePicker from "../../../components/atoms/custom-date-time-picker/custom-date-time-picker";
import { CustomButton } from "../../../components";
import ConveyorInputConfigTable from "./ConvayorInputConfigTable";
import ConveyorInputHistoryTable from "./ConveyorInputHistoryTable";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";

interface ICoveyorInputPageProps {
  openTab?: number;
}

export const CoveyorInputPage: React.FC<ICoveyorInputPageProps> = ({
  openTab = 0,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(openTab);
  const [dueDate, setDueDate] = React.useState<Dayjs | null>(null);
  const [inputValues, setInputValues] = useState({
    invoice: "",
    location: "",
  });
  const [editingField, setEditingField] = useState<
    "invoice" | "location" | null
  >(null);
  const isAddEnabled =
    inputValues.invoice.trim() !== "" && inputValues.location.trim() !== "";
  const [rowCount, setRowCount] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <PosLayout>
      <PosInnerPageLayout title={""}>
        <Box sx={{ padding: "1rem -2rem" }}>
          <Typography
            sx={{
              fontSize: pxToRem(48),
              fontWeight: "bold",
              color: Colors.MAIN_GRAY,
              marginTop: "1rem",
              marginLeft: pxToRem(66),
              flex: 1,
            }}
          >
            Rack Config
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "-2rem",
              marginLeft: pxToRem(70),
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "& .MuiTab-root": {
                  background: Colors.BACKSHADE_GRAY,
                  color: Colors.PRIMARY,
                  height: pxToRem(43),
                  minHeight: pxToRem(43),
                  width: "11.9285714286rem",
                  padding: 0,
                  marginTop: "3rem",
                  textTransform: "none",
                },
                "& .Mui-selected": {
                  background: Colors.PRIMARY,
                  color: `${Colors.WHITE} !important`,
                },
                "& .MuiTab-root:first-of-type": {
                  borderRadius: "0.71rem 0 0 0",
                },
                "& .MuiTab-root:nth-of-type(2)": {
                  borderRadius: "0 0.71rem 0 0",
                },
              }}
            >
              <Tab
                label="Config"
                sx={{ fontWeight: "bold", fontSize: "1.142rem" }}
              />
              <Tab
                label="History"
                sx={{ fontWeight: "bold", fontSize: "1.142rem" }}
              />
            </Tabs>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: Colors.BACKSHADE_GRAY,
            padding: "2rem",
            borderRadius: "0 0.71rem 0.71rem 0.71rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "flex-start",
            height: pxToRem(827),
            width: pxToRem(1726),
            marginLeft: pxToRem(70),
            gap: "1rem",
          }}
        >
          {selectedTab === 0 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "2rem 2rem",
                }}
              >
                <Box
                  sx={{
                    width: pxToRem(756),
                    height: pxToRem(320),
                    backgroundColor: Colors.WHITE,
                    borderRadius: pxToRem(15),
                    textAlign: "center",
                    padding: pxToRem(37),
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(32),
                      fontWeight: "bold",
                      color: Colors.TEXT_GRAY_DARK,
                    }}
                  >
                    Invoice
                  </Typography>

                  <Box
                    sx={{
                      width: pxToRem(684),
                      height: pxToRem(188),
                      backgroundColor: Colors.BACKSHADE_GRAY,
                      borderRadius: pxToRem(10),
                      marginTop: pxToRem(10),
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditingField("invoice")}
                  >
                    {editingField === "invoice" ? (
                      <Input
                        value={inputValues.invoice}
                        onChange={(e) =>
                          setInputValues({
                            ...inputValues,
                            invoice: e.target.value,
                          })
                        }
                        autoFocus
                        disableUnderline
                        onBlur={() => setEditingField(null)}
                        sx={{
                          fontSize: pxToRem(64),
                          color: Colors.TEXT_GRAY_DARK,
                          textAlign: "center",
                          width: "100%",
                          "& .MuiInputBase-input": { textAlign: "center" },
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: pxToRem(64),
                          color:
                            inputValues.invoice.trim() === ""
                              ? Colors.ACCENT_GRAY_DISABLED
                              : Colors.TEXT_GRAY_DARK,
                        }}
                      >
                        {inputValues.invoice.trim() === ""
                          ? "INXXXXX"
                          : inputValues.invoice}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: pxToRem(756),
                    height: pxToRem(320),
                    backgroundColor: Colors.WHITE,
                    borderRadius: pxToRem(15),
                    textAlign: "center",
                    padding: pxToRem(37),
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(32),
                      fontWeight: "bold",
                      color: Colors.TEXT_GRAY_DARK,
                    }}
                  >
                    Location
                  </Typography>

                  <Box
                    sx={{
                      width: pxToRem(684),
                      height: pxToRem(188),
                      backgroundColor: Colors.BACKSHADE_GRAY,
                      borderRadius: pxToRem(10),
                      marginTop: pxToRem(10),
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditingField("location")}
                  >
                    {editingField === "location" ? (
                      <Input
                        value={inputValues.location}
                        onChange={(e) =>
                          setInputValues({
                            ...inputValues,
                            location: e.target.value,
                          })
                        }
                        autoFocus
                        disableUnderline
                        onBlur={() => setEditingField(null)}
                        sx={{
                          fontSize: pxToRem(64),
                          color: Colors.TEXT_GRAY_DARK,
                          textAlign: "center",
                          width: "100%",
                          "& .MuiInputBase-input": { textAlign: "center" },
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: pxToRem(64),
                          color:
                            inputValues.location.trim() === ""
                              ? Colors.ACCENT_GRAY_DISABLED
                              : Colors.TEXT_GRAY_DARK,
                        }}
                      >
                        {inputValues.location.trim() === ""
                          ? "XXX"
                          : inputValues.location}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <CustomButton
                  sx={{
                    width: pxToRem(126),
                    height: pxToRem(46),
                    fontSize: pxToRem(16),
                    marginRight: pxToRem(25),
                    marginTop: pxToRem(-15),
                  }}
                  disabled={!isAddEnabled}
                >
                  Add
                </CustomButton>
              </Box>

              <Box
                sx={{
                  width: pxToRem(1635),
                  height: pxToRem(230),
                  marginLeft: pxToRem(20),
                  marginTop: pxToRem(20),
                  borderRadius: pxToRem(10),
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ConveyorInputConfigTable setRowCount={setRowCount} />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: pxToRem(10),
                    marginLeft: pxToRem(25),
                  }}
                >
                  <IOSSwitch />
                  <Typography
                    sx={{ fontSize: pxToRem(16), color: Colors.MAIN_GRAY }}
                  >
                    Select All
                  </Typography>
                </Box>

                <CustomButton
                  sx={{
                    width: pxToRem(150),
                    height: pxToRem(46),
                    fontSize: pxToRem(16),
                    marginRight: pxToRem(25),
                    marginTop: pxToRem(10),
                  }}
                  disabled={rowCount === 0}
                >
                  Submit
                </CustomButton>
              </Box>
            </>
          )}

          {selectedTab === 1 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "1rem 2rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: pxToRem(5),
                    position: "relative",
                    marginTop: pxToRem(-25),
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(18),
                      color: Colors.TEXT_GRAY_DARK,
                      marginTop: pxToRem(20),
                    }}
                  >
                    Date:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomDateTimePicker
                      type="date"
                      value={dueDate}
                      onChange={(newValue) => setDueDate(newValue)}
                      sx={{
                        width: pxToRem(268),
                        height: pxToRem(42),
                        fontSize: pxToRem(16),
                        "& .MuiInputBase-root": {
                          height: pxToRem(42),
                        },
                        "& input": {
                          height: pxToRem(42),
                          fontSize: pxToRem(16),
                        },
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: pxToRem(20),
                    justifyContent: "flex-end",
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: pxToRem(5),
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: pxToRem(18),
                        color: Colors.TEXT_GRAY_DARK,
                      }}
                    >
                      Rack:
                    </Typography>

                    <Select
                      fullWidth
                      size="small"
                      defaultValue="01"
                      sx={{
                        width: pxToRem(268),
                        height: pxToRem(42),
                        fontSize: pxToRem(16),
                        backgroundColor: Colors.WHITE,
                      }}
                    >
                      <MenuItem value="01" sx={{ fontSize: pxToRem(16) }}>
                        01
                      </MenuItem>
                      <MenuItem value="02" sx={{ fontSize: pxToRem(16) }}>
                        02
                      </MenuItem>
                      <MenuItem value="03" sx={{ fontSize: pxToRem(16) }}>
                        03
                      </MenuItem>
                      <MenuItem value="04" sx={{ fontSize: pxToRem(16) }}>
                        04
                      </MenuItem>
                      <MenuItem value="05" sx={{ fontSize: pxToRem(16) }}>
                        05
                      </MenuItem>
                      <MenuItem value="All" sx={{ fontSize: pxToRem(16) }}>
                        All
                      </MenuItem>
                    </Select>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: pxToRem(5),
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: pxToRem(18),
                        color: Colors.TEXT_GRAY_DARK,
                      }}
                    >
                      Location:
                    </Typography>
                    <Select
                      fullWidth
                      size="small"
                      defaultValue="100"
                      sx={{
                        width: pxToRem(268),
                        height: pxToRem(42),
                        fontSize: pxToRem(16),
                        backgroundColor: Colors.WHITE,
                      }}
                    >
                      <MenuItem value="100" sx={{ fontSize: pxToRem(16) }}>
                        100
                      </MenuItem>
                      <MenuItem value="101" sx={{ fontSize: pxToRem(16) }}>
                        101
                      </MenuItem>
                      <MenuItem value="102" sx={{ fontSize: pxToRem(16) }}>
                        102
                      </MenuItem>
                      <MenuItem value="103" sx={{ fontSize: pxToRem(16) }}>
                        103
                      </MenuItem>
                      <MenuItem value="104" sx={{ fontSize: pxToRem(16) }}>
                        104
                      </MenuItem>
                      <MenuItem value="105" sx={{ fontSize: pxToRem(16) }}>
                        105
                      </MenuItem>
                    </Select>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: pxToRem(10),
                    }}
                  >
                    <TextField
                      placeholder="Search"
                      sx={{
                        width: pxToRem(268),
                        marginTop: pxToRem(30),
                        backgroundColor: Colors.WHITE,
                        "& .MuiInputBase-root": {
                          fontSize: pxToRem(16),
                          height: pxToRem(42),
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <SearchIcon
                            sx={{
                              width: pxToRem(18),
                              height: pxToRem(18),
                              color: Colors.MAIN_GRAY,
                            }}
                          />
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: pxToRem(10),
                }}
              >
                <ConveyorInputHistoryTable />
              </Box>
            </>
          )}
        </Box>
      </PosInnerPageLayout>
    </PosLayout>
  );
};

export default CoveyorInputPage;
