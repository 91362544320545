import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

import CustomModal from "../../common/custom-modal/custom-modal";
import { ISelectedUpcharge } from "../../../typings/interfaces/pos/upCharge";
import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import { sortArray } from "../../../util/array-functions";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { IupchargeResponse, IUpchargeVariant } from "../../../typings";

export interface IUpchargesModalProps {
  open: boolean;
  onClose(): void;
  upcharge: IupchargeResponse;
  onAdd(upcharges: ISelectedUpcharge): void;
  onRemove(upcharges: ISelectedUpcharge): void;
}

const UpchargesModal: FC<IUpchargesModalProps> = ({
  open,
  onClose,
  upcharge,
  onAdd,
  onRemove,
}) => {
  const modifiedSubUpchargePrices: IUpchargeVariant[] = [
    ...upcharge.upchargeVariants,
    ...(!upcharge.upchargeVariants.some(
      (subUpcharge) => subUpcharge.id === null
    )
      ? [
          {
            id: null,
            upchargeId: upcharge.id,
            upchargeVariant: "custom",
            price: 0,
            deleted: false,
          },
        ]
      : []),
  ];

  const subUpchargesSorted = sortArray({
    array: modifiedSubUpchargePrices,
    sortByKey: "upchargeVariant",
    fixedFirstItem: "custom",
  });

  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const alreadyAddedUpcharge: boolean =
    (selectedCartItem?.upcharges.filter(
      (cartUpcharge) => cartUpcharge.id === upcharge.id
    ).length ?? 0) > 0;

  const [selectedSubUpcharge, setSelectedSubUpcharge] =
    useState<IUpchargeVariant>(
      (alreadyAddedUpcharge
        ? selectedCartItem?.upcharges.filter(
            (cartUpcharge) => cartUpcharge.id === upcharge.id
          )[0].subUpcharge
        : {
            id: null,
            upchargeId: null,
            upchargeVariant: "custom",
            price: 0,
            deleted: false,
          }) as IUpchargeVariant
    );
  const [amount, setAmount] = useState<number>(selectedSubUpcharge.price);
  const [comment, setComment] = useState<string>(
    selectedSubUpcharge.id === null ? "" : selectedSubUpcharge.upchargeVariant
  );

  const handleOnClickSubUpcharge = (subUpcharge: IUpchargeVariant) => {
    setSelectedSubUpcharge(subUpcharge);
    if (subUpcharge.id === null) {
      setAmount(0);
      setComment("");
    } else {
      setAmount(subUpcharge.price);
      setComment(subUpcharge.upchargeVariant);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSubUpcharge({ ...selectedSubUpcharge, price: +e.target.value });
    setAmount(+e.target.value);
  };
  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSubUpcharge({
      ...selectedSubUpcharge,
      upchargeVariant: e.target.value,
    });
    setComment(e.target.value);
  };

  const handleAddUpcharge = () => {
    const addingUpcharge: ISelectedUpcharge = {
      id: upcharge.id,
      label: upcharge.upcharge,
      subUpcharge: selectedSubUpcharge,
    };
    onAdd(addingUpcharge);
    onClose();
  };
  const handleRemoveUpcharge = () => {
    onRemove(
      selectedCartItem?.upcharges.filter(
        (cartUpcharge) => cartUpcharge.id === upcharge.id
      )[0] as ISelectedUpcharge
    );
    onClose();
  };

  return (
    <CustomModal
      title={"Upcharges"}
      openModal={open}
      width="55.4464286rem"
      height="57.5rem"
      onClose={onClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          paddingTop: "1rem",
        }}
      >
        <Box
          sx={{
            borderRadius: "0.714285714rem",
            background: Colors.BACKGROUND_GRAY,
            padding: "1.728571429rem",
            display: "flex",
            flexDirection: "column",
            gap: "1.428571rem",
            height: "21.23500rem",
            minHeight: "21.23500rem",
            maxHeight: "21.23500rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.7rem",
              fontWeight: 600,
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            {upcharge.upcharge} Options
          </Typography>

          <Swiper
            style={{ width: "40.8464286rem" }}
            spaceBetween={2}
            mousewheel={true}
            direction="horizontal"
          >
            {[...Array(Math.ceil(subUpchargesSorted.length / 9))].map(
              (swipePage, swipePageIndex) => {
                return (
                  <SwiperSlide
                    key={swipePageIndex}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      gap: "0.9rem",
                      flexWrap: "wrap",
                      width: "40.8464286rem",
                    }}
                  >
                    {subUpchargesSorted
                      .slice(swipePageIndex * 9, (swipePageIndex + 1) * 9)
                      .map((subUpcharge, i) => (
                        <CustomButton
                          key={(subUpcharge as any).id}
                          sx={{
                            background:
                              selectedSubUpcharge.id === (subUpcharge as any).id
                                ? Colors.SECONDARY
                                : Colors.MAIN_GRAY,
                            fontSize: "1.35142857rem",
                            width: "12.84rem",
                            height: "4rem",
                            borderRadius: "0.714285714rem",
                            lineHeight: "1.4rem",
                          }}
                          onClick={() =>
                            handleOnClickSubUpcharge(
                              subUpcharge as IUpchargeVariant
                            )
                          }
                          disabled={
                            subUpchargesSorted.length === 1 &&
                            (subUpchargesSorted[0] as any).id === null
                          }
                        >
                          {(subUpcharge as IUpchargeVariant).upchargeVariant}
                        </CustomButton>
                      ))}
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            borderRadius: "0.714285714rem",
            background: Colors.BACKGROUND_GRAY,
            padding: "1.728571429rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.7rem",
              fontWeight: 600,
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            Upcharge Details
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.3rem",
                  fontWeight: 600,
                  width: "7rem",
                  color: Colors.TEXT_GRAY_DARK,
                }}
              >
                Amount
              </Typography>
              <CustomTextBox
                type="number"
                sx={{ flexGrow: 1 }}
                disabled={selectedSubUpcharge.id !== null}
                value={amount}
                onChange={handleAmountChange}
                placeholder="Enter amount"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.3rem",
                  fontWeight: 600,
                  width: "7rem",
                  color: Colors.TEXT_GRAY_DARK,
                }}
              >
                Comment
              </Typography>
              <CustomTextBox
                sx={{ flexGrow: 1 }}
                disabled={selectedSubUpcharge.id !== null}
                value={comment}
                onChange={handleCommentChange}
                placeholder="Enter comment"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "0.5rem",
          }}
        >
          <CustomButton
            sx={{
              height: "4.5rem",
              width: "12rem",
              borderRadius: "0.71429rem",
              fontSize: "1.5rem",
              background: "none",
              border: `0.11429rem solid ${Colors.MAIN_GRAY}`,
              color: Colors.MAIN_GRAY,
              fontWeight: 600,
            }}
            onClick={handleRemoveUpcharge}
            disabled={!alreadyAddedUpcharge}
          >
            Remove
          </CustomButton>
          <CustomButton
            sx={{
              height: "4.5rem",
              width: "13.5rem",
              borderRadius: "0.71429rem",
              fontSize: "1.5rem",
              fontWeight: 600,
            }}
            disabled={amount <= 0 || comment === ""}
            onClick={handleAddUpcharge}
          >
            {alreadyAddedUpcharge ? "Update" : "Add"}
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default UpchargesModal;
