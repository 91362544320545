import React, { FC, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import CustomModal from "../../common/custom-modal/custom-modal";
import { Colors } from "../../../configs";
import { useAppDispatch } from "../../../hooks";
import { CartSliceAction } from "../../../redux/slice/cartSlice";

interface IActionTileModalProps {
  openModal: boolean;
  onClose(): void;
  actionItems: Array<{
    icon: JSX.Element;
    label: string;
    onClick: () => void;
    color: string;
  }>;
  defaultWidth?: string;
  defaultHeight?: string;
}

export const ActionTileModal: FC<IActionTileModalProps> = ({
  openModal,
  onClose,
  actionItems,
  defaultHeight = "11.5rem",
}) => {
  const dispatch = useAppDispatch();

  const modalWidth = `${12.89285714285 * actionItems.length}rem`;

  useEffect(() => {
    return () => {
      dispatch(CartSliceAction.clearBill());
    };
  }, []);

  return (
    <CustomModal
      title=""
      openModal={openModal}
      width={modalWidth}
      height={defaultHeight}
      onClose={onClose}
      mode="popup"
      disableBackdropClick={true}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "2rem",
          marginTop: "-4.4rem",
        }}
      >
        {actionItems.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              onClick={item.onClick}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                width: "12rem",
                height: { xs: "7rem", sm: "9rem" },
                marginTop: "0",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  marginBottom: "0.5rem",
                  marginTop: { xs: "1rem", sm: "1.5rem" },
                  color: item.color,
                }}
              >
                {React.cloneElement(item.icon, {
                  sx: { fontSize: "3.571rem" },
                })}
              </Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: item.color,
                  whiteSpace: "nowrap",
                  marginTop: "0",
                }}
              >
                {item.label}
              </Typography>
            </Box>

            {/* Vertical line between items */}
            {index < actionItems.length - 1 && (
              <Box
                sx={{
                  width: "2px",
                  height: "8rem",
                  backgroundColor: Colors.BACKSHADE_GRAY,
                  marginTop: "1rem",
                }}
              />
            )}
          </Box>
        ))}
      </Box>
    </CustomModal>
  );
};
