import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Typography } from "@mui/material";
import { FC, useState } from "react";
import { Colors } from "../../../configs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import { CustomButton, CustomTextBox } from "../../atoms";
import "./notesCard.css";
import { NoteOccasion } from "../../../typings/enum/noteOccasions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useParams } from "react-router-dom";
import ConfirmDialog from "../../atoms/confirm-box/confirm-box";
import {
  deleteBillNoteAction,
  getBillNoteByIdAction,
  updateBillNoteAction,
} from "../../../redux/actions/cartAction";
import { CartSliceAction } from "../../../redux/slice/cartSlice";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { BillNoteResponse } from "../../../typings/interfaces/responses/bill-note-response";

export interface INotesCardProps {
  noteMode: "temp" | "perm";
  note: BillNoteResponse;
}

const BillNotesCard: FC<INotesCardProps> = ({ note, noteMode }) => {
  const [noteText, setNoteText] = useState(note.note);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const params = useParams();
  const customerId = params.id;
  const { bills: bill, temporaryBillNotes } = useAppSelector(
    (state) => state.cart
  );
  const { user } = useAppSelector((state) => state.auth);
  const snackbar = useSnackbarContext();

  interface IHandleExpandChangeProps {
    newExpanded?: boolean;
  }

  const handleExpandChange = ({
    newExpanded,
  }: IHandleExpandChangeProps = {}) => {
    if (newExpanded !== undefined) {
      setExpanded(newExpanded);
    } else {
      setExpanded((prevExpanded) => !prevExpanded);
    }
  };

  const handleNoteEdit = (newNote: string) => {
    setNoteText(newNote);
  };

  // const handleOnUpdateNote = async () => {
  //   if (storedNote.length > 0) {
  //     if (customerId && user) {
  //       if (bill !== null && Number(bill.id) > 0) {
  //         await dispatch(
  //           updateBillNoteAction({
  //             id: noteId,
  //             note: storedNote,
  //             occasions: [] as NoteOccasion[],
  //             // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
  //             merchantUserId: 1,
  //             customerId: Number(customerId),
  //             billId: Number(bill.id),
  //           })
  //         ).then((response) => {
  //           // if (response) {
  //           //   console.log(JSON.stringify(response));
  //           // } else {

  //           // }
  //           dispatch(getBillNoteByIdAction({ billId: String(noteId) }));
  //         });
  //       } else {
  //         dispatch(
  //           CartSliceAction.setTemporaryBillNotes([
  //             ...temporaryBillNotes.filter((note) => note.tempId !== noteId),
  //             {
  //               tempId: noteId,
  //               note: storedNote,
  //               merchantUserId: 1,
  //               // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
  //               cashierFirstName: user.firstName,
  //               cashierLastName: user.lastName,
  //               customerId: Number(customerId),
  //               occasions: [] as NoteOccasion[],
  //               date: new Date().toISOString(),
  //             },
  //           ])
  //         );
  //       }
  //     }
  //   } else {
  //     snackbar.showSnackbar("Cannot add empty notes", "warning");
  //   }
  // };

  const handleOnUpdateNote = async () => {
    if (noteText.length > 0) {
      if (noteMode === "perm") {
        await dispatch(
          updateBillNoteAction({
            id: note.id,
            note: noteText,
            merchantUserId: 1,
            customerId: note.customerId,
            occasions: note.noteShowOccasion as NoteOccasion[],
            billId: note.billId,
          })
        ).then((response) => {
          if (response) {
            dispatch(getBillNoteByIdAction({ billId: note.billId }));
          }
        });
      } else {
        dispatch(
          CartSliceAction.setTemporaryBillNotes([
            ...temporaryBillNotes.filter(
              (storedNote) => storedNote.tempId !== note.id
            ),
            {
              tempId: note.id,
              note: noteText,
              merchantUserId: 1,
              cashierFirstName: note.reportedUserFirstName,
              cashierLastName: note.reportedUserLastName,
              customerId: note.customerId,
              occasions: note.noteShowOccasion,
              date: new Date().toISOString(),
            },
          ])
        );
      }
    } else {
      snackbar.showSnackbar("Cannot add empty notes", "warning");
    }
  };

  const handleOnDeleteNote = async () => {
    if (customerId && user) {
      if (noteMode === "perm") {
        await dispatch(deleteBillNoteAction(String(note.id)));
        dispatch(getBillNoteByIdAction({ billId: note.billId }));
      } else {
        dispatch(
          CartSliceAction.setTemporaryBillNotes([
            ...temporaryBillNotes.filter(
              (storedNote) => storedNote.tempId !== note.id
            ),
          ])
        );
      }
    }
  };

  return (
    <>
      <ConfirmDialog
        open={deleteConfirmation}
        title="Delete Confirmation"
        message={<Typography>Do you want to delete this note?</Typography>}
        positiveButtonText="Yes"
        negativeButtonText="No"
        onCancel={() => setDeleteConfirmation(false)}
        onConfirm={() => handleOnDeleteNote()}
      />
      <Box
        sx={{
          borderRadius: "0.714285714rem",
          padding: "1.35232143rem",
          background: Colors.BACKGROUND_GRAY,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "2.28571429rem",
          paddingLeft: "2.28571429rem",
          // marginTop: "0.91rem",
        }}
      >
        <IconButton
          sx={{
            background: Colors.SECONDARY,
            borderRadius: "0.303571429rem",
            width: "2.28571429rem",
            height: "2rem",
            boxShadow: `0rem 0.214285714rem 0.285714286rem rgba(0, 0, 0, 0.25)`,
            color: Colors.WHITE,
          }}
          onClick={() => setDeleteConfirmation(true)}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            gap: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.1rem",
              fontWeight: 500,
            }}
          >
            {note.note}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1.57142857rem",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: Colors.SECONDARY, marginRight: "auto" }}>
              Added by{" "}
              {`${note.reportedUserFirstName} ${note.reportedUserLastName}`}
            </Typography>
            <Box
              sx={{
                color: Colors.SECONDARY,
                gap: "0.714285714rem",
                alignItems: "center",
                display: note.createdTime ? "flex" : "none",
              }}
            >
              <CalendarMonthIcon
                sx={{ display: note.createdTime ? "inline-flex" : "none" }}
              />
              <Typography>
                {(note.createdTime
                  ? new Date(note.createdTime)
                  : null
                )?.toLocaleDateString("en-US")}
              </Typography>
            </Box>
            <IconButton
              sx={{
                width: "1.71428571rem",
                height: "1.71428571rem",
                borderRadius: "0.214285714rem",
                color: Colors.WHITE,
                background: Colors.SECONDARY,
              }}
              onClick={() => handleExpandChange()}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box
        className={
          expanded ? " collapsingSection" : "collapsedSection collapsingSection"
        }
        sx={{
          transition: "all 0.5s",
          marginBottom: "13.75rem",
          marginTop: "0.91rem",
          height: "8.17rem",
        }}
      >
        <Box
          sx={{
            borderRadius: "0.714285714rem",
            padding: "1.35232143rem",
            background: Colors.BACKGROUND_GRAY,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "1.86rem",
            marginBottom: "0.91rem",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <CustomTextBox
              multiline
              sx={{
                width: "100%",
                background: Colors.WHITE,
                border: "none",
                "& .MuiInputBase-input": {
                  height: "4.5rem",
                  minHeight: "4.5rem",
                  maxHeight: "4.5rem",
                },
              }}
              value={noteText}
              onChange={(event) => handleNoteEdit(event.target.value)}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "row",
              gap: "0.57rem",
              width: "100%",
            }}
          >
            <IconButton
              type="button"
              sx={{
                border: `0.0714285714rem solid ${Colors.MAIN_GRAY}`,
                borderRadius: "0.214285714rem",
                background: "none",
                height: "2.62571429rem",
                aspectRatio: "1/1",
              }}
              onClick={() => setDeleteConfirmation(true)}
            >
              <DeleteIcon
                sx={{
                  color: Colors.MAIN_GRAY,
                  fontSize: "1.42857143rem",
                  marginTop: "-0.0714285714rem",
                }}
              />
            </IconButton>
            <CustomButton
              type="button"
              onClick={() => {
                handleExpandChange({ newExpanded: false });
              }}
              sx={{
                border: `0.0714285714rem solid ${Colors.MAIN_GRAY}`,
                borderRadius: "0.214285714rem",
                background: "none",
                color: Colors.MAIN_GRAY,
                fontSize: "0.91rem",
                fontWeight: "600",
                padding: "0.46rem 2.31rem",
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="button"
              sx={{
                border: "none",
                borderRadius: "0.214285714rem",
                background: "#018752",
                color: "#f8f8f8",
                fontSize: "0.91rem",
                fontWeight: "600",
                padding: "0.46rem 2.31rem",
              }}
              disabled={noteText.length === 0}
              // onClick={handleOnUpdateNote}
              onClick={() => {
                handleOnUpdateNote();
                handleExpandChange({ newExpanded: false });
              }}
            >
              Update
            </CustomButton>
          </Box>
        </Box>
        <Box
          sx={{
            width: "5.31rem",
            height: "0rem",
            borderTop: "0.142857143rem solid #d9d9d9",
            margin: "0.357142857rem auto",
          }}
        ></Box>
      </Box>
    </>
  );
};

export default BillNotesCard;
