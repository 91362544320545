import { Box, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import CustomModal from "../../common/custom-modal/custom-modal";
import { ISelectedSpot, ISubSpot } from "../../../typings/interfaces/pos/spot";
import { CustomButton, CustomTextBox } from "../../atoms";
import { Colors } from "../../../configs";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { ISpotResponse } from "../../../typings";

export interface ISpotModalProps {
  open: boolean;
  onClose: () => void;
  spot: ISpotResponse;
  onAdd: (spot: ISelectedSpot) => void;
  onRemove: (spot: ISelectedSpot) => void;
}

const SpotModal: FC<ISpotModalProps> = ({
  open,
  onClose,
  onAdd,
  onRemove,
  spot,
}) => {
  const modifiedSubSpotPrices = [
    ...(spot.showNoFee ? [{ id: 1, comment: "No Fee", price: 0 }] : []),
    {
      id: 2,
      comment: "Minor",
      price: spot.minorPrice,
    },
    {
      id: 3,
      comment: "Medium",
      price: spot.mediumPrice,
    },
    {
      id: 4,
      comment: "Major",
      price: spot.majorPrice,
    },
    ...(spot.showCustomFee
      ? [{ id: null, comment: "Custom", price: null }]
      : []),
  ];

  const { selectedCartItem } = useAppSelector((state) => state.cart);

  const alreadyAddedSpot: boolean =
    (selectedCartItem?.spots.filter((s) => s.id === spot.id).length ?? 0) > 0;

  const [selectedSubSpot, setSelectedSubSpot] = useState<ISubSpot>(
    (alreadyAddedSpot
      ? selectedCartItem?.spots.filter((cartSpot) => cartSpot.id === spot.id)[0]
          .subSpot
      : modifiedSubSpotPrices[0]) as ISubSpot
  );

  const [amount, setAmount] = useState<number>(selectedSubSpot.price ?? 0);
  const [comment, setComment] = useState<string>(
    selectedSubSpot.id === null ? "" : selectedSubSpot.comment
  );

  const handleOnClickSubSpot = (subSpot: ISubSpot) => {
    setSelectedSubSpot(subSpot);
    if (subSpot.id === null) {
      setAmount(0);
      setComment("");
    } else {
      setAmount(subSpot.price ?? 0);
      setComment(subSpot.comment);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setSelectedSubSpot({ ...selectedSubSpot, price: +e.target.value });
    setAmount(parseFloat(e.target.value));
  };
  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSubSpot({ ...selectedSubSpot, comment: e.target.value });
    setComment(e.target.value);
  };

  const handleRemoveSpot = () => {
    onRemove(
      selectedCartItem?.spots.filter(
        (cartSpot) => cartSpot.id === spot.id
      )[0] as ISelectedSpot
    );
    onClose();
  };
  const handleAddSpot = () => {
    const addingSpot: ISelectedSpot = {
      id: spot.id,
      label: spot.spot,
      subSpot: { ...selectedSubSpot, price: amount },
    };
    onAdd(addingSpot);
    onClose();
  };

  return (
    <CustomModal
      title={"Spot"}
      openModal={open}
      width="55.4464286rem"
      // height="34.5rem"
      height="57.5rem"
      onClose={onClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          paddingTop: "1rem",
        }}
      >
        <Box
          sx={{
            borderRadius: "0.714285714rem",
            background: Colors.BACKGROUND_GRAY,
            padding: "1.728571429rem",
            display: "flex",
            flexDirection: "column",
            gap: "1.428571rem",
            height: "21.23500rem",
            minHeight: "21.23500rem",
            maxHeight: "21.23500rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.7rem",
              fontWeight: 600,
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            {spot.spot} Options
          </Typography>
          {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.9rem",
            flexWrap: "wrap",
          }}
        >
          {subUpchargesSorted.map((subUpcharge) => {
            return (
              <CustomButton
                key={(subUpcharge as ISubUpcharge).id}
                sx={{
                  background:
                    selectedSubUpcharge === subUpcharge
                      ? Colors.SECONDARY
                      : Colors.MAIN_GRAY,
                  fontSize: "1.35142857rem",
                  width: "12.84rem",
                  height: "4rem",
                  borderRadius: "0.714285714rem",
                  lineHeight: "1.4rem",
                }}
                onClick={() =>
                  setSelectedSubUpcharge(subUpcharge as ISubUpcharge)
                }
              >
                {(subUpcharge as ISubUpcharge).label}
              </CustomButton>
            );
          })}
        </Box> */}
          <Swiper
            style={{ width: "40.8464286rem" }}
            spaceBetween={2}
            mousewheel={true}
            direction="horizontal"
          >
            {[...Array(Math.ceil(modifiedSubSpotPrices.length / 9))].map(
              (swipePage, swipePageIndex) => {
                return (
                  <SwiperSlide
                    key={swipePageIndex}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      gap: "0.9rem",
                      flexWrap: "wrap",
                      width: "40.8464286rem",
                    }}
                  >
                    {modifiedSubSpotPrices
                      .slice(swipePageIndex * 9, (swipePageIndex + 1) * 9)
                      .map((subSpot, i) => (
                        <CustomButton
                          key={(subSpot as ISubSpot).id}
                          sx={{
                            background:
                              selectedSubSpot.id === (subSpot as ISubSpot).id
                                ? Colors.SECONDARY
                                : Colors.MAIN_GRAY,
                            fontSize: "1.35142857rem",
                            width: "12.84rem",
                            height: "4rem",
                            borderRadius: "0.714285714rem",
                            lineHeight: "1.4rem",
                          }}
                          onClick={() =>
                            handleOnClickSubSpot(subSpot as ISubSpot)
                          }
                          // disabled={
                          //   subSpotsSorted.length === 1 &&
                          //   (subSpotsSorted[0] as any).id === 100000
                          // }
                        >
                          {(subSpot as ISubSpot).comment}
                        </CustomButton>
                      ))}
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            borderRadius: "0.714285714rem",
            background: Colors.BACKGROUND_GRAY,
            padding: "1.728571429rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.7rem",
              fontWeight: 600,
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            Spot Details
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.3rem",
                  fontWeight: 600,
                  width: "7rem",
                  color: Colors.TEXT_GRAY_DARK,
                }}
              >
                Amount
              </Typography>
              <CustomTextBox
                type="number"
                sx={{ flexGrow: 1 }}
                value={amount}
                onChange={handleAmountChange}
                placeholder="Enter amount"
                disabled={
                  !(
                    selectedSubSpot.price === null ||
                    selectedSubSpot.id === null
                  )
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.3rem",
                  fontWeight: 600,
                  width: "7rem",
                  color: Colors.TEXT_GRAY_DARK,
                }}
              >
                Comment
              </Typography>
              <CustomTextBox
                sx={{ flexGrow: 1 }}
                value={comment}
                onChange={handleCommentChange}
                placeholder="Enter comment"
                disabled={selectedSubSpot.id !== null && spot.id !== null}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "0.5rem",
          }}
        >
          <CustomButton
            sx={{
              height: "4.5rem",
              width: "12rem",
              borderRadius: "0.71429rem",
              fontSize: "1.5rem",
              background: "none",
              border: `0.11429rem solid ${Colors.MAIN_GRAY}`,
              color: Colors.MAIN_GRAY,
              fontWeight: 600,
            }}
            onClick={handleRemoveSpot}
            disabled={!alreadyAddedSpot}
          >
            Remove
          </CustomButton>
          <CustomButton
            sx={{
              height: "4.5rem",
              width: "13.5rem",
              borderRadius: "0.71429rem",
              fontSize: "1.5rem",
              fontWeight: 600,
            }}
            disabled={
              (amount <= 0 && selectedSubSpot.id !== 1) || comment === ""
            }
            onClick={handleAddSpot}
          >
            {alreadyAddedSpot ? "Update" : "Add"}
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default SpotModal;
