import { useEffect, useState } from "react";
import { FabricType } from "../typings";
import { ITypeItem } from "../typings/interfaces/pos/typeItem";
import { FabricTypeResponse } from "../typings/interfaces/responses/fabricTypeResponse";
import { useAppSelector } from "./reduxHooks";

const usePricing = () => {
  const { selectedServiceType, previousServiceType, selectedCartItem } =
    useAppSelector((state) => state.cart);
  const [subCategories, setSubCategories] = useState<Array<ITypeItem>>([]);
  const [materialTypes, setMaterialTypes] = useState<Array<ITypeItem>>([]);
  const [alterationSecondSubCategories, setAlterationSecondSubCategories] =
    useState<Array<ITypeItem>>([]);
  const [secondSubCategories, setSecondSubCategories] = useState<
    Array<ITypeItem>
  >([]);

  const {
    categoryResponse: categoryList,
    subCategoryResponse: garmentType,
    secondSubCategoryResponse: secondSubCategory,
    dryCleanResponse: dryCleanPrice,
    fabricTypesResponse: fabricType,
    categoryRelationshipResponse: categoryRelationship,
    categoryMaterialRelationship: categoryMaterialRelationship,
    subCategoryMaterialRelationship,
  } = useAppSelector((state) => state.pricing);

  useEffect(() => {
    getSecondSubCategories();
    getMaterialTypes();
    getGarmentTypes();
  }, [categoryRelationship]);

  useEffect(() => {
    filterAlterationSecondSubCategories();
  }, [selectedCartItem?.alterationItems, selectedCartItem?.garmentType?.id]);

  const getGarmentTypes = () => {
    const serviceTypeId =
      selectedServiceType?.id === -100000
        ? previousServiceType?.id
        : selectedServiceType?.id;
    const garmentTypes: Array<ITypeItem> | undefined =
      categoryRelationship?.body
        .filter((item) => item.categoryId === serviceTypeId)
        .map((item) => {
          return {
            name: item.subCategoryName,
            id: item.subCategoryId,
            icon: item.icon,
          };
        });
    // filter gamentTypes as unique list from id
    const uniqueList = garmentTypes?.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    if (uniqueList) setSubCategories(uniqueList);
  };

  const getMaterialTypes = () => {
    const serviceTypeId =
      selectedServiceType?.id === -100000
        ? previousServiceType?.id
        : selectedServiceType?.id;
    const materialTypes: Array<ITypeItem> | undefined =
      categoryMaterialRelationship?.body
        .filter((item) => item.categoryId === serviceTypeId)
        .map((item) => {
          return { name: item.materialName, id: item.materialId };
        });
    // filter gamentTypes as unique list from id
    const uniqueList = materialTypes?.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    if (uniqueList) setMaterialTypes(uniqueList);
  };

  const getSecondSubCategories = () => {
    const serviceTypeId =
      selectedServiceType?.id === -100000
        ? previousServiceType?.id
        : selectedServiceType?.id;
    const secondSubCategories: Array<ITypeItem> | undefined =
      categoryRelationship?.body
        .filter((item) => item.categoryId === serviceTypeId)
        .map((item) => {
          return { name: item.subCategoryName, id: item.subCategoryId };
        });
    // filter gamentTypes as unique list from id
    const uniqueList = secondSubCategories?.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    if (uniqueList) setSecondSubCategories(uniqueList);
  };

  const getGarmentStyleList = (garmentTypeId: number) => {
    const serviceTypeId =
      selectedServiceType?.id === -100000
        ? previousServiceType?.id
        : selectedServiceType?.id;
    const secondSubCategories: Array<ITypeItem> | undefined =
      categoryRelationship?.body
        .filter(
          (item) =>
            item.categoryId === serviceTypeId &&
            item.subCategoryId === garmentTypeId
        )
        .map((item) => {
          return {
            name: item.secondSubCategoryName,
            id: item.secondSubCategoryId,
          };
        });
    // filter gamentTypes as unique list from id
    const uniqueList = secondSubCategories?.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    // if(uniqueList)
    // setSecondSubCategories(uniqueList);
    return uniqueList;
  };

  const filterAlterationSecondSubCategories = () => {
    if (!selectedCartItem) return;
    const secondSubCategories: Array<ITypeItem> | undefined =
      subCategoryMaterialRelationship?.body
        .filter((item) =>
          selectedCartItem.alterationItems.some(
            (alteration) =>
              item.materialId === alteration.alterationSubCategory?.id &&
              item.subCategoryId === selectedCartItem.garmentType?.id
          )
        )
        .map((item) => {
          return {
            name: item.secondSubCategoryName,
            id: item.secondSubCategoryId,
          };
        });

    const uniqueList = secondSubCategories?.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );

    if (uniqueList) setAlterationSecondSubCategories(uniqueList);
  };

  // const getDryCleanPrice = (
  //   categoryId: number,
  //   subCategoryId: number,
  //   secondSubCategoryId: number,
  //   fabricType: FabricTypeResponse
  // ) => {
  //   // const dcPrice = dryCleanPrice?.body.filter(
  //   //   (item) =>
  //   //     item.category === categoryId &&
  //   //     item.subCategory === subCategoryId &&
  //   //     item.secondSubCategory === secondSubCategoryId &&
  //   //     item.fabricType === fabricType.id
  //   // );
  //   return dcPrice && dcPrice?.length > 0 ? dcPrice[0].price : 0;
  // };

  return {
    categoryList,
    subCategories,
    materialTypes,
    secondSubCategories,
    getGarmentStyleList,
    alterationSecondSubCategories,
    // getDryCleanPrice,
  };
};

export default usePricing;
