import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { image1, image2, image3, image4 } from "./imagesStrings";

const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    fontFamily: "Helvetica",
  },
  section: {
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
    marginBottom: 10,
  },
  label: {
    width: "35%",
    textAlign: "left",
    fontSize: 8,
  },
  colon: {
    width: "5%",
    textAlign: "center",
    fontSize: 8,
  },
  value: {
    width: "60%",
    textAlign: "left",
    paddingLeft: 8,
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    bottom: 300,
    left: 50,
    width: "100%",
    textAlign: "center",
    fontSize: 40,
    opacity: 0.1,
    transform: "rotate(-45deg)",
  },
});

export const ExpenditureReport = ({
  name,
  customerId,
  address,
  contactNumber,
  total,
  month,
}: {
  name: string;
  customerId: string;
  address: string;
  contactNumber: string;
  total: number;
  month: string;
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image
        src={image1}
        style={{ width: "100%", height: "auto", marginBottom: 10 }}
      />

      <View
        style={{
          marginBottom: 15,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          position: "absolute",
          left: "8rem",
          top: "3.8rem",
          gap: "15.1rem",
        }}
      >
        <View>
          <Text
            style={{
              fontSize: 11,
              fontWeight: "bold",
              color: "#00796B",
              marginBottom: 0.5,
            }}
          >
            Presto Valet
          </Text>
          <Text style={{ fontSize: 9, marginBottom: 1 }}>
            Certified Environmental Dry Cleaner
          </Text>
          <Text style={{ fontSize: 9, marginBottom: 1 }}>
            37, O’Conner St, Bloomberg, UK
          </Text>
          <Text style={{ fontSize: 9, marginBottom: 1 }}>
            TP: +360 245 675 467 | presto@prestovalet.net
          </Text>
          <Text style={{ fontSize: 9 }}>www.prestovalet.net</Text>
        </View>

        <Image src={image2} style={{ width: "100px", height: "50px" }} />
      </View>

      <View
        style={{
          borderBottomWidth: 1.5,
          borderBottomColor: "#018752",
          marginVertical: 10,
          marginTop: 40,
          marginLeft: 96,
          marginRight: 30,
        }}
      />

      <View
        style={{
          width: "470px",
          height: "660px",
          backgroundColor: "#F6F6F6",
          borderRadius: "15px",
          marginLeft: 96,
          padding: 18,
        }}
      >
        <View
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: 30,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              color: "#505050",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            Monthly Expenditure Report
          </Text>
          <Text style={{ fontSize: 10, color: "#505050", fontWeight: "bold" }}>
            {month}
          </Text>
        </View>

        <View style={styles.section}>
          {[
            ["Customer Name", name],
            ["Customer ID", customerId],
            ["Address", address],
            ["Contact Number", contactNumber],
            ["Total Monthly Amount", `$ ${total.toFixed(2)}`],
          ].map(([label, value], index) => (
            <View key={index} style={styles.row}>
              <Text style={styles.label}>{label}</Text>
              <Text style={styles.colon}>:</Text>
              <Text style={styles.value}>{value}</Text>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.footer}>
        <Text>Thank You!</Text>
        <Text>See you soon...</Text>
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginLeft: 96,
          marginTop: 1,
          marginBottom: 10,
          marginRight: 30,
        }}
      >
        <Text style={{ fontSize: 6, color: "#515151" }}>
          Originally developed by: DryCleanX
        </Text>
        <Text style={{ fontSize: 6, color: "#515151" }}>
          Printed by: CBH3674 | John Roy | 01/26/2025 20:55
        </Text>
        <Image src={image3} style={{ width: 35, height: 10 }} />
      </View>

      <Image
        src={image4}
        style={{
          width: "100%",
          height: "auto",
          position: "absolute",
          bottom: 0,
        }}
      />
    </Page>
  </Document>
);

// PDF Download Wrapper
export const ReportDownload = () => {
  return (
    <PDFDownloadLink
      document={
        <ExpenditureReport
          name="John Doe"
          customerId="GH8267"
          address="37, O'Conner St, Bloomberg, UK"
          contactNumber="+360 245 675 467"
          total={567.5}
          month="February - 2025"
        />
      }
      fileName="Monthly_Expenditure_Report_Feb_2025.pdf"
    >
      {({ loading }) =>
        loading ? (
          <span>Preparing document...</span>
        ) : (
          <span>Download Monthly Report</span>
        )
      }
    </PDFDownloadLink>
  );
};
