import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { Colors } from "../../configs";
import { PosLayout } from "../../components";
import InfoSection from "../../components/pos/info-section/Info-section";
import ServicesSection from "../../components/pos/services-section/services-section";
import TopRightButton from "../../components/common/top-right-button/top-right-button";
import {
  useNavigate,
  useParams,
  useLocation,
  useBlocker,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { CartSliceAction } from "../../redux/slice/cartSlice";
import { CustomerSliceAction } from "../../redux/slice/customerSlice";
import ConfirmDialog from "../../components/atoms/confirm-box/confirm-box";
import { IDraft } from "../../typings/interfaces/pos/draft";
import { formatPhoneNumber } from "../../util/common-functions";
import { useSnackbarContext } from "../../providers/SnackbarProvider";
import ExitConfirmationModal from "../../components/pos/info-section/cart/ExitConfirmationModal";
import { getCustomerByIdAction } from "../../redux/actions/customerAction";

export interface IPosDashboardPageProps {}

const PosDashboardPage: FC<IPosDashboardPageProps> = () => {
  const [draftConfirmationDialogOpen, setDraftConfirmationDialog] =
    useState<boolean>(false);
  const [allowNavigation, setAllowNavigation] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(
    null
  );
  const { drafts, cart } = useAppSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { customer } = useAppSelector((state) => state.customer);
  const { showSnackbar } = useSnackbarContext();

  const params = useParams();
  const customerId = params.id;

  const handleOnClickTopRight = () => {
    let isNonEmptySession = false;
    cart.forEach((session) => {
      if (session.cartItems?.filter((item) => item.finalized).length > 0) {
        isNonEmptySession = true;
        return;
      } else {
        isNonEmptySession = false;
      }
    });
    if (isNonEmptySession) {
      setPendingNavigation("/pos/lobby");
      setDraftConfirmationDialog(true);
    } else {
      navigate("/pos/lobby");
    }
    // setDraftConfirmationDialog(true);
  };

  const hasUnsavedChanges = cart.some(
    (session) => session.cartItems?.length > 0
  );

  const blocker = useBlocker(({ nextLocation }) => {
    if (hasUnsavedChanges && !allowNavigation) {
      setPendingNavigation(nextLocation.pathname);
      setDraftConfirmationDialog(true);
      return true;
    }
    return false;
  });

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasUnsavedChanges]);

  const handleConfirmExit = () => {
    setAllowNavigation(true);
    if (pendingNavigation) {
      const targetPage = pendingNavigation;
      setPendingNavigation(null);
      navigate(targetPage, { replace: true });
    } else {
      navigate("/pos/lobby");
    }
    setDraftConfirmationDialog(false);
  };

  const handleCancelExit = () => {
    setDraftConfirmationDialog(false);
    setPendingNavigation(null);
  };

  useEffect(() => {
    return () => {
      dispatch(CustomerSliceAction.clearCustomer());
      dispatch(CustomerSliceAction.clearCustomerNotes());
      dispatch(CartSliceAction.clearBill());
    };
  }, []);

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomerByIdAction(customerId));
    }
  }, [customerId]);

  useEffect(() => {
    if (allowNavigation && pendingNavigation) {
      navigate(pendingNavigation);
      setPendingNavigation(null);
    }
  }, [allowNavigation, pendingNavigation]);

  const handleOnDraftConfirm = async () => {
    if (!customer) {
      showSnackbar("Customer not found", "error");
      return;
    }

    let finalizedCart = cart
      .map((session) => ({
        ...session,
        cartItems: session.cartItems?.filter((item) => item.finalized),
      }))
      .filter((session) => session.cartItems?.length);

    const finalizedDraft: IDraft = {
      draftId: drafts.length + 1,
      customer: customer,
      draftSessions: finalizedCart,
      createdAt: new Date(),
      totalPieces: finalizedCart.reduce(
        (acc, session) =>
          acc + session.cartItems!.reduce((acc, item) => acc + item.qty, 0),
        0
      ),
      totalAmount: finalizedCart.reduce(
        (acc, session) =>
          acc +
          session.cartItems!.reduce(
            (acc, item) => acc + item.qty * item.unitPrice,
            0
          ),
        0
      ),
    };

    await dispatch(CartSliceAction.setDrafts([...drafts, finalizedDraft]));

    setAllowNavigation(true);

    if (pendingNavigation) {
      const targetPage = pendingNavigation;
      setPendingNavigation(null);
      navigate(targetPage, { replace: true });
    }

    setDraftConfirmationDialog(false);
  };

  return (
    <PosLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          gap: "0.57rem",
        }}
      >
        <ExitConfirmationModal
          open={draftConfirmationDialogOpen}
          title="Exit Confirmation"
          message="Do you want to save customer sessions as a draft or discard?"
          saveButtonText="Save and exit"
          discardButton={{
            text: "Discard and Exit",
            onClick: () => {
              setAllowNavigation(true);
              if (pendingNavigation) {
                const targetPage = pendingNavigation;
                setPendingNavigation(null);
                navigate(targetPage, { replace: true });
              }
              setDraftConfirmationDialog(false);
            },
          }}
          onCancel={handleCancelExit}
          onConfirm={async () => {
            await handleOnDraftConfirm();
            setDraftConfirmationDialog(false);
          }}
        />
        <InfoSection />
        <Box
          sx={{ width: "0.142857143rem", background: Colors.ACCENT_GRAY }}
        ></Box>
        <ServicesSection />
        <TopRightButton variation="Back" onClick={handleOnClickTopRight} />
      </Box>
    </PosLayout>
  );
};

export default PosDashboardPage;
