import { FC, useEffect, useRef } from "react";
import { Grid, Box, CircularProgress } from "@mui/material";

import CartRow from "./cart-row";
import { Colors } from "../../../../configs";
import { CartItem } from "../../../../typings/interfaces/pos/cart-item";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";

export interface ICartSectionProps {
  onSelect(index: number): void;
  mode?: "checkin" | "pickup";
}

const CartSection: FC<ICartSectionProps> = ({ onSelect, mode = "checkin" }) => {
  const { selectedSession, cartMode, billByIdLoading } = useAppSelector(
    (state) => state.cart
  );
  const dispatch = useAppDispatch();
  // const { getDryCleanPrice } = usePricing();

  const handleOnCartItemClick = (item: CartItem) => {
    dispatch(CartSliceAction.setSelectedCartItem(item));
  };

  const boxRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedSession?.cartItems?.filter((row) => row.finalized).length]);

  const styles = {
    tableHeadingCell: {
      borderRadius: "0.71rem",
      background: Colors.SECONDARY,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem 0.71rem",
      boxSizing: "border-box",
      color: "white",
      fontSize: "1.142857142857143rem",
      whiteSpace: "nowrap",
    },
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        paddingY: "0.71rem",
        paddingLeft: "0.71rem",
        paddingRight: "0.45rem",
        gap: "1.14rem",
        height: cartMode === "pickup" ? "33.4rem" : "28.42rem",
      }}
    >
      <Box>
        <Grid container sx={{ gap: "1.21428571rem", height: "100%" }}>
          <Grid item xs={12}>
            <Box style={{ position: "sticky", top: 0 }}>
              <Grid
                container
                sx={{
                  gap: "0.35rem",
                  flexWrap: "nowrap",
                }}
              >
                <Grid item xs={1.01} sx={styles.tableHeadingCell}>
                  Qty
                </Grid>
                <Grid item xs={6.5} sx={styles.tableHeadingCell}>
                  Description
                </Grid>
                <Grid item xs={1.64} sx={styles.tableHeadingCell}>
                  Status
                </Grid>
                <Grid item xs={1.27} sx={styles.tableHeadingCell}>
                  Unit Price
                </Grid>
                <Grid item xs={1.27} sx={styles.tableHeadingCell}>
                  Total
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ height: "22.9rem" }}>
            <Box
              ref={boxRef}
              sx={{
                height: "22.9rem",
                overflowY: "auto",
                marginRight: " -0.125rem",
                "&::-webkit-scrollbar": {
                  position: "absolute",
                  width: "0.15rem",
                  right: "8rem",
                  paddingRight: "-0.8rem",
                  marginRight: "-2rem",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ADADAD",
                  borderRadius: "0rem",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "0rem",
                },
                // make scrolling animation smooth
                scrollBehavior: "smooth",
              }}
            >
              {selectedSession?.cartItems
                // ?.filter((item) => item.finalized)
                ?.map((cartItem, i) => {
                  return (
                    <CartRow
                      key={i}
                      onSelect={() => handleOnCartItemClick(cartItem)}
                      cartItem={cartItem}
                      mode={mode}
                      // data={
                      // {
                      // qty: cartItem.qty,
                      // serviceType: cartItem?.serviceType?.categoryName || "",
                      // textureType: cartItem.texture || "",
                      // color: cartItem.garmentColor || [],
                      // materialType: cartItem.materialType?.name || "",
                      // clothSubType: cartItem?.garmentType?.name || "",
                      // clothType: cartItem?.garmentStyle?.name || "",
                      // instructions: cartItem.instructions,
                      // damages: cartItem.damages,
                      // upcharges: cartItem.upcharges || [],
                      // status: "In Progress",
                      // unitPrice: cartItem.unitPrice,
                      // note: cartItem.note,
                      // }
                      // }
                      status="In Progress"
                      firstRow={i === 0}
                      lastRow={
                        i ===
                        selectedSession.cartItems?.length - 1
                      }
                    />
                  );
                })}
              {selectedSession === null &&
                cartMode === "pickup" &&
                billByIdLoading && (
                  <Box sx={{ width: "100%" }}>
                    <CircularProgress sx={{ mx: "auto", mt: "3rem" }} />
                  </Box>
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CartSection;
