import { FC, useEffect, useState } from "react";
import {
  NextPlan,
  ArrowCircleRight,
  Cancel,
  ReplayCircleFilled,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { render } from "react-thermal-printer";
import dayjs, { Dayjs } from "dayjs";
import { v4 as uuidv4 } from "uuid";

import CheckInSummary from "./checkinSummary";
import Payment from "./payment";
import Success from "./success";
import Failure from "./failure";
import CustomModal from "../../common/custom-modal/custom-modal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Colors } from "../../../configs";
import { PaymentModalContents } from "../../../typings/enum/paymentModalContents";
import {
  addBillNoteAction,
  addSession,
  newBillAction,
  newPaymentAction,
} from "../../../redux/actions/cartAction";
import { getTenantId } from "../../../util/common-functions";
import useCart from "../../../hooks/useCart";
import useThermalPrinter from "../../../hooks/thermalPrinter";
import { useTranslation } from "../../../util/translation-provider";
import { getStoreBillTemplate } from "../../../configs/printCopyTemplates";
import getCheckOutDate from "../../../configs/checkoutDateRules";
import { PaymentTypeEnum } from "../../../typings/enum/paymentTypes";
import { CartSliceAction } from "../../../redux/slice/cartSlice";
import { IPaymentRequest } from "../../../typings/interfaces/requests";
import { PaymentCreateResponse } from "../../../typings/interfaces/responses/paymentResponse";
import { BaseResponseSingle } from "../../../typings";
import { IHalfPayment } from "../../../typings/interfaces/payment/halfPayment";
import { IStoreConfigResponse } from "../../../typings/interfaces/responses/store-config-response";
import { ActionTileModal } from "../../atoms/action-tile-modal/action-tile-modal";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { ICartSession } from "../../../typings/interfaces/pos/cart-session";
import { IBillResponse } from "../../../typings/interfaces/responses/new-bill-response";
import { store } from "../../../redux/store";

export interface IPaymentModalData {
  paymentMode: PaymentTypeEnum;
  paidAmount: number;
}

export interface IBillPayment {
  billId: number;
  billIdWithCode: string;
  billTotal: number;
  paidAmount: number;
}

export interface IPaymentModalProps {
  discount: number;
  total: number;
  subTotal?: number;
  open: boolean;
  onClose(): void;
  // onCheckout(data: IPaymentModalData): void;
  content: PaymentModalContents;
}

export interface ModalActionItem {
  icon: JSX.Element;
  label: string;
  onClick: () => void;
  color: string;
}

const PaymentModal: FC<IPaymentModalProps> = ({
  open,
  onClose,
  discount,
  total,
}) => {
  const [openActionTileModal, setOpenActionTileModal] = useState(false);
  const [isCheckInSuccessful] = useState<boolean>(true);
  const [modalActionItems, setModalActionItems] = useState<ModalActionItem[]>(
    []
  );
  const [intiaLRender, setIntiaLRender] = useState(true);
  const navigate = useNavigate();
  const { billsError, bills } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const getStoreConfigResponse = useAppSelector(
    (state) => state.store.getStoreConfigResponse
  );
  const tenantId = getTenantId();
  const {
    cartTotal,
    totalDiscountAmount,
    getSessionDiscountById,
    getSessionTotalBySessionId,
  } = useCart();
  const { initialize, print } = useThermalPrinter();
  const translation = useTranslation();
  const { customer } = useAppSelector((state) => state.customer);
  const { cart, paymentHalfDetails } = useAppSelector((state) => state.cart);
  // const billTotal = cartTotal - totalDiscountAmount;
  const totalBillAmountFromHalfPayment = paymentHalfDetails.reduce(
    (acc, item) => acc + item.fullpaymentAmount,
    0
  );
  const paidAmount = paymentHalfDetails.reduce(
    (acc, item) => acc + item.halfPaymentAmount,
    0
  );
  const snackbar = useSnackbarContext();
  const params = useParams();

  const [masterSwitch, setMasterSwitch] = useState(false);
  const [sessionSwitches, setSessionSwitches] = useState<
    { id: string; checked: boolean }[]
  >([]);
  const userId = Number(params.id);

  const customerId = params.id;

  const successActionItems = [
    {
      icon: <NextPlan />,
      label: "Go to Lobby",
      onClick: () => {
        dispatch(CartSliceAction.clearSession());
        setIntiaLRender(true);
        navigate("/pos/lobby");
      },
      color: Colors.PRIMARY,
    },
    {
      icon: <ArrowCircleRight />,
      label: "Stay Here",
      onClick: () => {
        setOpenActionTileModal(false);
        dispatch(CartSliceAction.clearSession());
        setIntiaLRender(true);
        const uuId = uuidv4();
        const newSession: ICartSession = {
          sessionId: uuId,
          cartItems: [],
          customerId: userId,
          pinTag: {
            color: null,
            tags: [],
          },
        };
        dispatch(addSession(newSession));
      },
      color: Colors.PRIMARY,
    },
    {
      icon: <NextPlan />,
      label: "Go to Pickup",
      onClick: () => {
        dispatch(CartSliceAction.clearSession());
        setIntiaLRender(true);
        navigate(`/pos/customer/${customerId}/history`);
      },
      color: Colors.PRIMARY,
    },
  ];

  const failureActionItems = [
    {
      icon: <Cancel />,
      label: "Cancel",
      onClick: () => setOpenActionTileModal(false),
      color: Colors.BROWN,
    },
    {
      icon: <ReplayCircleFilled />,
      label: "Retry",
      onClick: () => console.log("Retry clicked"),
      color: Colors.BROWN,
    },
  ];

  const parseTime = (time: string) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0);
    return date;
  };

  const defaultCutOffTime = "10:00:00";
  const defaultPickupTime = "15:00:00";
  const defaultWorkingDays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];
  const defaultRegularServiceDays = 2;
  const defaultSpecialServiceDays = 0;

  const checkInCutOffTimeDate = parseTime(
    getStoreConfigResponse?.body?.cutOffTime || defaultCutOffTime
  );
  const pickupTimeDate = parseTime(
    getStoreConfigResponse?.body?.pickupTime || defaultPickupTime
  );

  const [masterDate, setMasterDate] = useState<Dayjs | null>(null);
  const [masterTime, setMasterTime] = useState<Dayjs | null>(null);
  const [sessionDates, setSessionDates] = useState<
    { id: string; specialDate: Dayjs }[]
  >([]);
  const [sessionTimes, setSessionTimes] = useState<
    { id: string; specialTime: Dayjs }[]
  >([]);
  const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(
    PaymentTypeEnum.CASH
  );

  useEffect(() => {
    setMasterDate(
      getCheckOutDate({
        isSpecialDay: masterSwitch,
        checkInCutOffTime: checkInCutOffTimeDate,
        pickupTime: pickupTimeDate,
        workingDays:
          getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
        generalServiceDays:
          getStoreConfigResponse?.body?.daysToDeliver ??
          defaultRegularServiceDays,
        specialServiceDays:
          getStoreConfigResponse?.body?.specialDaysToDeliver ??
          defaultSpecialServiceDays,
      })
    );
    setMasterTime(
      dayjs(
        new Date().setHours(
          getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
          getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
          0,
          0
        )
      )
    );
  }, [getStoreConfigResponse]);

  useEffect(() => {
    setSessionSwitches(
      cart.map((item) => ({ id: item.sessionId, checked: false }))
    );
    setSessionDates(
      cart.map((item) => ({
        id: item.sessionId,
        specialDate: getCheckOutDate({
          isSpecialDay: false,
          checkInCutOffTime: checkInCutOffTimeDate,
          pickupTime: pickupTimeDate,
          workingDays:
            getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
          generalServiceDays:
            getStoreConfigResponse?.body?.daysToDeliver ??
            defaultRegularServiceDays,
          specialServiceDays:
            getStoreConfigResponse?.body?.specialDaysToDeliver ??
            defaultSpecialServiceDays,
        }),
      }))
    );
    setSessionTimes(
      cart.map((item) => ({
        id: item.sessionId,
        specialTime: dayjs(
          new Date().setHours(
            getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
            getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
            0,
            0
          )
        ),
      }))
    );
  }, [cart]);

  useEffect(() => {
    setSessionDates((prevSessionDates) => {
      return prevSessionDates.map((sessionDate) => {
        const sessionSwitch = sessionSwitches.find(
          (item) => item?.id === sessionDate?.id
        );
        if (sessionSwitch && sessionSwitch.checked && masterSwitch) {
          return {
            id: sessionDate?.id,
            specialDate: dayjs(masterDate),
          };
        }
        if (sessionSwitch && sessionSwitch.checked && !masterSwitch) {
          return {
            id: sessionDate?.id,
            specialDate: getCheckOutDate({
              isSpecialDay: true,
              checkInCutOffTime: checkInCutOffTimeDate,
              pickupTime: pickupTimeDate,
              workingDays:
                getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
              generalServiceDays:
                getStoreConfigResponse?.body?.daysToDeliver ??
                defaultRegularServiceDays,
              specialServiceDays:
                getStoreConfigResponse?.body?.specialDaysToDeliver ??
                defaultSpecialServiceDays,
            }),
          };
        }
        if (sessionSwitch && !sessionSwitch.checked && masterSwitch) {
          return {
            id: sessionDate?.id,
            specialDate: getCheckOutDate({
              isSpecialDay: false,
              checkInCutOffTime: checkInCutOffTimeDate,
              pickupTime: pickupTimeDate,
              workingDays:
                getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
              generalServiceDays:
                getStoreConfigResponse?.body?.daysToDeliver ??
                defaultRegularServiceDays,
              specialServiceDays:
                getStoreConfigResponse?.body?.specialDaysToDeliver ??
                defaultSpecialServiceDays,
            }),
          };
        }
        if (sessionSwitch && !sessionSwitch.checked && !masterSwitch) {
          return {
            id: sessionDate?.id,
            specialDate: getCheckOutDate({
              isSpecialDay: false,
              checkInCutOffTime: checkInCutOffTimeDate,
              pickupTime: pickupTimeDate,
              workingDays:
                getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
              generalServiceDays:
                getStoreConfigResponse?.body?.daysToDeliver ??
                defaultRegularServiceDays,
              specialServiceDays:
                getStoreConfigResponse?.body?.specialDaysToDeliver ??
                defaultSpecialServiceDays,
            }),
          };
        }

        return sessionDate;
      });
    });
    setSessionTimes((prevSessionTimes) => {
      return prevSessionTimes.map((sessionTime) => {
        const sessionSwitch = sessionSwitches.find(
          (item) => item?.id === sessionTime?.id
        );

        if (sessionSwitch && sessionSwitch.checked && masterSwitch) {
          return {
            id: sessionTime?.id,
            specialTime: dayjs(masterTime),
          };
        }
        if (sessionSwitch && sessionSwitch.checked && !masterSwitch) {
          return {
            id: sessionTime?.id,
            specialTime: dayjs(
              new Date().setHours(
                getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
                getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
                0,
                0
              )
            ),
          };
        }
        if (sessionSwitch && !sessionSwitch.checked && masterSwitch) {
          return {
            id: sessionTime?.id,
            specialTime: dayjs(
              new Date().setHours(
                getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
                getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
                0,
                0
              )
            ),
          };
        }
        if (sessionSwitch && !sessionSwitch.checked && !masterSwitch) {
          return {
            id: sessionTime?.id,
            specialTime: dayjs(
              new Date().setHours(
                getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
                getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
                0,
                0
              )
            ),
          };
        }

        return sessionTime;
      });
    });
  }, [masterSwitch, masterDate, masterTime, cart]);

  const handleOnChangeMasterSwitch = () => {
    setMasterSwitch((prevState) => {
      const newSwitches = JSON.parse(JSON.stringify(sessionSwitches));
      newSwitches.forEach((item: any) => {
        item.checked = !prevState;
      });
      setSessionSwitches(newSwitches);
      const date = new Date();
      date.setHours(
        getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
        getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
        0,
        0
      );
      setMasterTime(dayjs(date));
      return !prevState;
    });
    const checkInCutOffTimeString =
      getStoreConfigResponse?.body?.cutOffTime || "10:00:00";
    const pickupTimeString =
      getStoreConfigResponse?.body?.pickupTime || "15:00:00";

    const [
      checkInCutOffTimeHours,
      checkInCutOffTimeMinutes,
      checkInCutOffTimeSeconds,
    ] = checkInCutOffTimeString.split(":").map(Number);
    const checkInCutOffTimeDate = new Date();
    checkInCutOffTimeDate.setHours(
      checkInCutOffTimeHours,
      checkInCutOffTimeMinutes,
      checkInCutOffTimeSeconds,
      0
    );

    const [pickupTimeHours, pickupTimeMinutes, pickupTimeSeconds] =
      pickupTimeString.split(":").map(Number);
    const pickupTimeDate = new Date();
    pickupTimeDate.setHours(
      pickupTimeHours,
      pickupTimeMinutes,
      pickupTimeSeconds,
      0
    );

    const date = {
      isSpecialDay: !masterSwitch,
      checkInCutOffTime: checkInCutOffTimeDate,
      pickupTime: pickupTimeDate,
      workingDays:
        getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
      generalServiceDays:
        getStoreConfigResponse?.body?.daysToDeliver ??
        defaultRegularServiceDays,
      specialServiceDays:
        getStoreConfigResponse?.body?.specialDaysToDeliver ??
        defaultSpecialServiceDays,
    };
    setMasterDate(getCheckOutDate(date));
  };
  const handleOnChangeMasterDate = (date: Dayjs | null) => {
    setMasterDate(date);
  };
  const handleOnChangeMasterTime = (time: Dayjs | null) => {
    setMasterTime(time);
  };

  const handleOnChangeSessionSwitch = (id: string) => {
    const newSwitches = JSON.parse(JSON.stringify(sessionSwitches));
    const index = newSwitches.findIndex((item: any) => item?.id === id);
    newSwitches[index].checked = !newSwitches[index].checked;
    setSessionSwitches(newSwitches);
  };
  const handleOnChangeSessionDate = (id: string, date: Dayjs | null) => {
    const newDates = JSON.parse(JSON.stringify(sessionDates));
    const index = newDates.findIndex((item: any) => item?.id === id);
    if (newDates[index]) {
      newDates[index].specialDate = date;
    }
    setSessionDates(newDates);
  };
  const handleOnChangeSessionTime = (id: string, time: Dayjs | null) => {
    const newTimes = JSON.parse(JSON.stringify(sessionTimes));
    const index = newTimes.findIndex((item: any) => item?.id === id);
    if (newTimes[index]) {
      newTimes[index].specialTime = time;
    }
    setSessionTimes(newTimes);
  };

  const [content, setContent] = useState<PaymentModalContents>(
    PaymentModalContents.SUMMARY
  );

  useEffect(() => {
    initialize();
  }, []);

  const updateHalfOrFullPayment = async (
    paymentRequest: IPaymentRequest,
    paymentModalData: IPaymentModalData
  ) => {
    dispatch(
      newPaymentAction({
        paymentType: paymentModalData.paymentMode,
        billId: paymentRequest.billId,
        customerId: paymentRequest.customerId,
        amount: Number(paymentModalData.paidAmount.toFixed(2)), // to fix floating-point precision errors due to js IEEE 754 standard
      })
    ).then(async (res) => {
      const payload = res?.payload as BaseResponseSingle<PaymentCreateResponse>;

      const updatedPaymentHalfDetails =
        store.getState().cart.paymentHalfDetails;

      const updatedHalfPayment = JSON.parse(
        JSON.stringify(
          // clone deep gave unexpected behavior so that's why used this method
          updatedPaymentHalfDetails.find(
            (item: IHalfPayment) => item.billId === payload.body.billId
          )
        )
      ) as IHalfPayment;
      if (updatedHalfPayment) {
        updatedHalfPayment.halfPaymentAmount += payload.body.amount;
        updatedHalfPayment.payments.push(payload.body);
        try {
          dispatch(
            CartSliceAction.setHalfPayementDetails([
              ...updatedPaymentHalfDetails.filter(
                (item: IHalfPayment) => item.billId !== payload.body.billId
              ),
              updatedHalfPayment,
            ])
          );
          snackbar.showSnackbar("Payment successful", "success");
        } catch (err: any) {
          snackbar.showSnackbar(`Failed to update payment`, "error");
        }
      } else {
        snackbar.showSnackbar(
          "Failed to find bill's saved half payment",
          "error"
        );
      }
    });
  };

  const temporaryNotes = useAppSelector(
    (state) => state.cart.temporaryBillNotes
  );

  const handleOnCheckout = (data: IPaymentModalData) => {
    if (!tenantId) {
      snackbar.showSnackbar("Tenant ID not found", "error");
      return;
    }
    if (!customer) {
      snackbar.showSnackbar("Customer not found", "error");
      return;
    }

    const totalBillAmount = cartTotal - totalDiscountAmount;
    const isFullPaymentDone = paidAmount >= totalBillAmount;

    if (isFullPaymentDone) {
      snackbar.showSnackbar("Payment already made", "warning");
    }

    if (paymentHalfDetails.length === 0) {
      const switchesAreGood: boolean = cart.every((session) => {
        const sessionSwitch = sessionSwitches.find(
          (switchItem) => switchItem.id === session.sessionId
        );
        return sessionSwitch?.checked !== undefined;
      });
      const datesAreGood: boolean = cart.every((session) => {
        const sessionDate = sessionDates.find(
          (dateItem) => dateItem.id === session.sessionId
        );
        return sessionDate?.specialDate !== undefined;
      });
      const timesAreGood: boolean = cart.every((session) => {
        const sessionTime = sessionTimes.find(
          (timeItem) => timeItem.id === session.sessionId
        );
        return sessionTime?.specialTime !== undefined;
      });

      if (!switchesAreGood || !datesAreGood || !timesAreGood) {
        snackbar.showSnackbar("Pickup details not found", "error");
        console.log("sessionSwitches", sessionSwitches);
        console.log("sessionDates", sessionDates);
        console.log("sessionTimes", sessionTimes);
      } else {
        console.log({
          tenant: tenantId,
          paymentType,
          sessionList: cart.map((session) => ({
            ...session,
            discount: {
              amount: getSessionDiscountById(session.sessionId),
              id: customer.discount ? Number(customer.discount) : null,
            },
            // discount: {
            //   amount: totalDiscountAmount,
            //   id: customer.discount ? Number(customer.discount) : null,
            // },
          })),
        });
        dispatch(
          newBillAction({
            tenant: tenantId,
            paymentType,
            sessionList: cart.map((session) => ({
              ...{
                ...session,
                pickupDetails: {
                  isSpecial: !!sessionSwitches.find(
                    (item) => item.id === session.sessionId
                  )?.checked,

                  // due date is combination of session date and session time
                  dueDate: (() => {
                    const specialDate = sessionDates.find(
                      (item) => item.id === session.sessionId
                    )?.specialDate;

                    const specialTime = sessionTimes.find(
                      (item) => item.id === session.sessionId
                    )?.specialTime;

                    if (specialDate && specialTime) {
                      return dayjs(specialDate)
                        .set("hour", specialTime.hour())
                        .set("minute", specialTime.minute())
                        .toISOString();
                    }

                    return new Date().toISOString(); // Handle undefined case appropriately
                  })(),
                },
              },
              discount: {
                amount: getSessionDiscountById(session.sessionId),
                id: customer.discount ? Number(customer.discount) : null,
              },
              // discount: {
              //   amount: totalDiscountAmount,
              //   id: customer.discount ? Number(customer.discount) : null,
              // },
            })),
          })
        ).then(async (res) => {
          const payload = res?.payload as IBillResponse[];

          if (!payload || payload.length === 0) {
            snackbar.showSnackbar("Failed to create bills.", "error");
            return;
          }

          // setup slot for half payment in redux
          const halfPaymentDetails = [];
          for (const bill of payload) {
            halfPaymentDetails.push({
              fullpaymentAmount: Number(bill.billAmount.toFixed(2)),
              halfPaymentAmount: 0,
              billId: bill.id,
              customerId: customer.id,
              payments: [],
            });
          }

          dispatch(CartSliceAction.setHalfPayementDetails(halfPaymentDetails));

          makePayment(
            data.paymentMode,
            data.paidAmount,
            customer.id,
            halfPaymentDetails
          );

          // Add Bill notes for the bill
          temporaryNotes.forEach((tempNote: any) => {
            const noteBill = payload.find(
              (bill: any) => bill.sessionId === tempNote.sessionId
            );
            if (noteBill?.id) {
              dispatch(
                addBillNoteAction({
                  note: tempNote.note,
                  occasions: tempNote.occasions,
                  merchantUserId: tempNote.merchantUserId,
                  customerId: tempNote.customerId,
                  billId: noteBill.id,
                })
              )
                .then(() => {
                  snackbar.showSnackbar("Note added successfully!", "success");

                  dispatch(
                    CartSliceAction.setTemporaryBillNotes(
                      temporaryNotes.filter(
                        (note: any) => note.tempId !== tempNote.tempId
                      )
                    )
                  );
                })
                .catch((err: any) => {
                  snackbar.showSnackbar(`Failed to add notes: ${err}`, "error");
                });
            }
          });
        });
        // console.log({
        //   tenant: tenantId,
        //   paymentType,
        //   sessionList: cart.map((session) => ({
        //     ...session,
        //     discount: {
        //       amount: getSessionDiscountById(session.sessionId),
        //       id: customer.discount ? Number(customer.discount) : null,
        //     },
        //     // discount: {
        //     //   amount: totalDiscountAmount,
        //     //   id: customer.discount ? Number(customer.discount) : null,
        //     // },
        //   })),
        // });
      }
    } else {
      makePayment(
        data.paymentMode,
        data.paidAmount,
        customer.id,
        paymentHalfDetails
      );
    }
  };

  const makePayment = async (
    paymentType: PaymentTypeEnum,
    totalPaid: number,
    customerId: number,
    halfPaymentProp: IHalfPayment[]
  ) => {
    let remaining = totalPaid;

    for (const bill of halfPaymentProp) {
      if (remaining <= 0) break;

      const billPayableAmount = bill.fullpaymentAmount - bill.halfPaymentAmount;
      // To Do: discount amount should be considered here

      if (bill.fullpaymentAmount <= bill.halfPaymentAmount) continue;

      const paymentAmount = Math.min(remaining, billPayableAmount);

      const paymentRequest: IPaymentRequest = {
        paymentType: paymentType,
        billId: bill.billId!,
        customerId: customerId,
        amount: Number(paymentAmount.toFixed(2)), // to fix floating-point precision errors due to js IEEE 754 standard
      };

      await updateHalfOrFullPayment(paymentRequest, {
        paymentMode: paymentType,
        paidAmount: paymentAmount,
      });

      remaining -= paymentAmount;
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    return () => {
      setContent(PaymentModalContents.SUMMARY);
    };
  }, [open]);

  useEffect(() => {
    if (
      Number(paidAmount.toFixed(2)) >=
      Number(totalBillAmountFromHalfPayment.toFixed(2))
    ) {
      if (intiaLRender) {
        // this is used to load the actiontile modal only after checkout
        setIntiaLRender(false);
        return;
      }
      onClose();
      if (
        (paymentHalfDetails.length === 0
          ? 0
          : paymentHalfDetails[paymentHalfDetails.length - 1]
              .halfPaymentAmount) >=
        (paymentHalfDetails.length === 0
          ? 0
          : paymentHalfDetails[paymentHalfDetails.length - 1].fullpaymentAmount)
      ) {
        setModalActionItems(successActionItems);
        setOpenActionTileModal(true);
      }

      if (!billsError) snackbar.showSnackbar("Order Successful", "success");
    }
  }, [paidAmount, paymentHalfDetails]);

  useEffect(() => {
    if (billsError) {
      setModalActionItems(failureActionItems);
      onClose();
      setOpenActionTileModal(true);
      snackbar.showSnackbar(`Order Failed: ${billsError}`, "error");
    }
  }, [billsError]);

  const contentNames = {
    summary: "Check in",
    payment: "Payment",
    success: "Payment",
    failure: "Payment",
  };

  const handleOnFinishSummary = () => {
    setContent(PaymentModalContents.PAYMENT);
  };

  const handleOnPayment = (data: IPaymentModalData) => {
    handleOnCheckout(data);
  };

  const handleContinue = () => {
    onClose();
  };

  const handleOnPaymentTypeChange = (paymentType: PaymentTypeEnum) => {
    setPaymentType(paymentType);
  };

  const contentComponents = {
    summary: (
      <CheckInSummary
        onCheckout={handleOnFinishSummary}
        masterSwitch={masterSwitch}
        sessionSwitches={sessionSwitches}
        masterDate={masterDate}
        masterTime={masterTime}
        sessionDates={sessionDates}
        sessionTimes={sessionTimes}
        handleOnChangeMasterSwitch={handleOnChangeMasterSwitch}
        handleOnChangeMasterDate={handleOnChangeMasterDate}
        handleOnChangeMasterTime={handleOnChangeMasterTime}
        handleOnChangeSessionSwitch={handleOnChangeSessionSwitch}
        handleOnChangeSessionDate={handleOnChangeSessionDate}
        handleOnChangeSessionTime={handleOnChangeSessionTime}
      />
    ),
    payment: (
      <Payment
        onCheckout={handleOnPayment}
        onPaymentTypeChange={handleOnPaymentTypeChange}
        paymentType={paymentType}
      />
    ),
    success: <Success onContinue={handleContinue} />,
    failure: <Failure onContinue={handleContinue} />,
  };

  return (
    <>
      <CustomModal
        title={contentNames[content]}
        openModal={open}
        width="95.0714286rem"
        height="64.7857143rem"
        onClose={handleOnClose}
      >
        {contentComponents[content]}
      </CustomModal>

      <ActionTileModal
        openModal={openActionTileModal}
        onClose={() => setOpenActionTileModal(false)}
        actionItems={
          isCheckInSuccessful ? successActionItems : failureActionItems
        }
      />
    </>
  );
};

export default PaymentModal;
