import { FC } from "react";
import { Box, Typography, Checkbox } from "@mui/material";

import { Colors } from "../../../../configs";
import { ICustomerBill } from "../../../../typings";
import { formatCurrency, formatDate } from "../../../../util/common-functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";
import { getBillItemIdListAction } from "../../../../redux/actions/cartAction";
import useCart from "../../../../hooks/useCart";

export interface ICustomerHistoryTableRowProps {
  data: ICustomerBill;
  onClick(data: ICustomerBill): void;
  onViewClick(data: ICustomerBill): void;
  firstRow: boolean;
  lastRow: boolean;
  isSelected: boolean;
}

const CustomerHistoryTableRow: FC<ICustomerHistoryTableRowProps> = ({
  data,
  firstRow,
  lastRow,
  onClick,
  onViewClick,
  isSelected,
}) => {
  const { cartHistorySelectedRows } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const { totalDiscountAmount } = useCart();

  const selectedRow = cartHistorySelectedRows.some(
    (row) => row.billId === data.billId
  );

  const selectRow = () => {
    if (selectedRow) {
      dispatch(
        CartSliceAction.setBillItemIdList(
          cartHistorySelectedRows.filter((row) => row.billId !== data.billId)
        )
      );
      dispatch(
        CartSliceAction.setCartHistorySelectedRows(
          cartHistorySelectedRows.filter((row) => row.billId !== data.billId)
        )
      );
    } else {
      dispatch(getBillItemIdListAction(data.id));
      dispatch(
        CartSliceAction.setCartHistorySelectedRows([
          ...cartHistorySelectedRows,
          data,
        ])
      );
    }
  };

  let styles = {
    tableBodyCell: {
      background: selectedRow
        ? Colors.ACCENT_GRAY_DISABLED
        : Colors.ACCENT_GRAY,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "1.14rem 0.28rem",
      paddingBottom: "0rem",
      boxSizing: "border-box",
      gap: "1.07rem",
      color: isSelected ? Colors.SECONDARY : Colors.TEXT_GRAY_DARK,
      fontWeight: "bold",
      zIndex: 0,
    },
    descriptionCell: {
      alignItems: "center",
      zIndex: 0,
    },
    tableRow: {
      zIndex: 0,
    },
  };

  const handleOnClickRow = () => {
    onClick(data);
  };

  return (
    // <Box
    //   sx={{ display: "flex", gap: "0.15rem", flexWrap: "nowrap" }}
    //   onClick={handleOnClickRow}
    // >
    <Box
      sx={{
        display: "flex",
        gap: "0.15rem",
        flexWrap: "nowrap",
        marginBottom: "0.15rem",
        zIndex: 0,
      }}
      onClick={handleOnClickRow}
    >
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          ...(lastRow ? { borderBottomLeftRadius: "0.71rem" } : {}),
          flex: 1,
          minWidth: { lg: "2.92857142857rem", md: "2.8rem", sm: "2.92rem" },
          maxWidth: {
            lg: "2.92857142857rem !important",
            md: "2.8rem !important",
            sm: "2.92rem !important",
          },
          width: "3.246rem !important",
          boxSizing: "border-box !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Checkbox onChange={(e) => selectRow()} value={selectedRow} />
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          ...styles.descriptionCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: { lg: "6.4rem", md: "6.4rem", sm: "6.4rem" },
          maxWidth: {
            lg: "6.4rem !important",
            md: "6.4rem !important",
            sm: "6.4rem !important",
          },
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>{data.billId}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "7.826rem",
          maxWidth: "7.826rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>
            {/* {data?.createdTime &&
              format(new Date(data.createdTime), "MM/dd/yyyy")} */}
            {formatDate(data.createdTime)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: { lg: "7rem", md: "7rem" },
          maxWidth: {
            lg: "7rem !important",
            md: "7rem !important",
          },
          padding: "0.5rem 0.71rem",
        }}
      >
        {/* cashier column */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>{data.cashierName}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "7.826rem",
          maxWidth: "7.826rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>
            {/* {data?.createdTime &&
              format(new Date(data.createdTime), "MM/dd/yyyy")} */}
            {formatDate(data.createdTime)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 2.5,
          minWidth: "5.606rem",
          maxWidth: "5.606rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>{data.totalItemCount ?? "N/A"}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 2.5,
          minWidth: "4.606rem",
          maxWidth: "4.606rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>{data.rackNumber}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 2.5,
          minWidth: "5.506rem",
          maxWidth: "5.506rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>
            {formatCurrency({
              amount: Math.max(0, data.billAmount - (data.paidAmount ?? 0)),
              prefix: "$",
            })}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 2.5,
          minWidth: "7.826rem",
          maxWidth: "7.826rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>{formatDate(data.lastPaymentDate)}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 2.5,
          minWidth: "7.73rem",
          maxWidth: "7.73rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>
            {data.lastPickupDate ? formatDate(data.lastPickupDate) : "N/A"}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 1.5,
          minWidth: "5.25rem",
          maxWidth: "5.25rem !important",
          padding: 0,
          ...(lastRow ? { borderBottomRightRadius: "0.71rem" } : {}),
          cursor: "pointer",
          backgroundColor: Colors.SECONDARY,
          "&:hover": {
            backgroundColor: Colors.SECONDARY,
          },
        }}
        onClick={() => {
          onViewClick(data);
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "1rem",
          }}
        >
          View
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomerHistoryTableRow;
