import React, { FC, useEffect, useState } from "react";
import { PosLayout } from "../../../components/layouts/pos-layout/PosLayout";
import {
  VictoryChart,
  VictoryBar,
  VictoryGroup,
  VictoryAxis,
  VictoryLegend,
  VictoryTheme,
} from "victory";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  Table,
  IconButton,
  Divider,
  MenuItem,
  Button,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  ArcElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";

import CustomerDetailSection from "../../../components/common/pos/customer-detail-section/customer-detail-section";
import { formatCurrency, formatUSDate } from "../../../util/common-functions";
import TopRightButton from "../../../components/common/top-right-button/top-right-button";
import { useNavigate, useParams } from "react-router-dom";
import PdfViewModal from "../../../components/pos/pdf-view-modal/pdf-view-modal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getAllCustomerInsightsAction,
  getCustomerActivitiesByDayAction,
  getCustomerOutstandingAction,
} from "../../../redux/actions/customerInsightsAction";
import { getAllCustomerLatestBillDetailsAction } from "../../../redux/actions/customerLatestBillDetailsAction";
import {
  getAllCustomerCurrentYearServiceUsageAction,
  getAllCustomerAllTimeServiceUsageAction,
} from "../../../redux/actions/customerServiceUsageAction";

import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { getAllCustomerAvgSpendingsAction } from "../../../redux/actions/customerAvgSpendingsAction";

import "react-datepicker/dist/react-datepicker.css";
import { pxToRem } from "../../../util";
import { Colors } from "../../../configs";
import { Payment } from "@mui/icons-material";
import { CustomerSliceAction } from "../../../redux/slice/customerSlice";

export interface ICustomerAnalysisProps {}

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Filler,
  Tooltip,
  Legend,
  Title,
  ArcElement
);

const firstPieOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
      text: "Current Year Service Usage",
    },
  },
};

const secondPieOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
      text: "All Time Service Usage",
    },
  },
};

const CustomerAnalysisScreen: FC<ICustomerAnalysisProps> = ({}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useAppDispatch();
  const Snackbar = useSnackbarContext();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const reportTypes = [
    "Annual Expenditure Report",
    "Monthly Expenditure Report",
    "Custom Expenditure Report",
  ];

  const [selectedReportType, setSelectedReportType] = useState("");
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState<string | null>(null);

  const [startDate, endDate] = dateRange;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const params = useParams();
  const customerId = params.id;

  const {
    customerInsights,
    customerInsightsError,
    customerLatestBillDetails,
    customerLatestBillDetailsError,
    customerAvgSpendings,
    customerAvgSpendingsError,
    customerActivitiesByDay,
    customerActivitiesByDayError,
    customerActivitiesByDayLoading,
    customerOutstandingError,
    customerOutstanding,
    customerCurrentYearServiceUsage,
    customerAllTimeServiceUsage,
  } = useAppSelector((state) => state.customer);

  useEffect(() => {
    if (customerId) {
      dispatch(getAllCustomerInsightsAction(Number(customerId)));
      dispatch(getAllCustomerLatestBillDetailsAction(Number(customerId)));

      dispatch(
        getAllCustomerAvgSpendingsAction({
          customerId: Number(customerId),
          startDate: null,
          endDate: null,
        })
      );

      dispatch(getCustomerActivitiesByDayAction(Number(customerId)));

      dispatch(getCustomerOutstandingAction(Number(customerId)));

      dispatch(getAllCustomerCurrentYearServiceUsageAction(Number(customerId)));
      dispatch(getAllCustomerAllTimeServiceUsageAction(Number(customerId)));
    }
  }, [customerId]);

  useEffect(() => {
    return () => {
      dispatch(CustomerSliceAction.clearCustomerInsights());
    };
  }, []);

  const barChartData = {
    value: [
      {
        name: "check-in",
        value: customerActivitiesByDay?.averageCheckinsPerDay?.Monday || 0,
        day: "Monday",
      },
      {
        name: "check-in",
        value: customerActivitiesByDay?.averageCheckinsPerDay?.Tuesday || 0,
        day: "Tuesday",
      },
      {
        name: "check-in",
        value: customerActivitiesByDay?.averageCheckinsPerDay?.Wednesday || 0,
        day: "Wednesday",
      },
      {
        name: "check-in",
        value: customerActivitiesByDay?.averageCheckinsPerDay?.Thursday || 0,
        day: "Thursday",
      },
      {
        name: "check-in",
        value: customerActivitiesByDay?.averageCheckinsPerDay?.Friday || 0,
        day: "Friday",
      },
      {
        name: "check-in",
        value: customerActivitiesByDay?.averageCheckinsPerDay?.Saturday || 0,
        day: "Saturday",
      },
      {
        name: "check-in",
        value: customerActivitiesByDay?.averageCheckinsPerDay?.Sunday || 0,
        day: "Sunday",
      },
      {
        name: "pickup",
        value: customerActivitiesByDay?.averagePickupsPerDay?.Monday || 0,
        day: "Monday",
      },
      {
        name: "pickup",
        value: customerActivitiesByDay?.averagePickupsPerDay?.Tuesday || 0,
        day: "Tuesday",
      },
      {
        name: "pickup",
        value: customerActivitiesByDay?.averagePickupsPerDay?.Wednesday || 0,
        day: "Wednesday",
      },
      {
        name: "pickup",
        value: customerActivitiesByDay?.averagePickupsPerDay?.Thursday || 0,
        day: "Thursday",
      },
      {
        name: "pickup",
        value: customerActivitiesByDay?.averagePickupsPerDay?.Friday || 0,
        day: "Friday",
      },
      {
        name: "pickup",
        value: customerActivitiesByDay?.averagePickupsPerDay?.Saturday || 0,
        day: "Saturday",
      },
      {
        name: "pickup",
        value: customerActivitiesByDay?.averagePickupsPerDay?.Sunday || 0,
        day: "Sunday",
      },
    ],
  };

  const barChartConfig = {
    xField: "day",
    yField: "value",
    colorField: "name",
    group: true,
  };

  useEffect(() => {
    if (customerInsightsError) {
      Snackbar.showSnackbar(customerInsightsError, "error");
    }

    if (customerLatestBillDetailsError) {
      Snackbar.showSnackbar(customerLatestBillDetailsError, "error");
    }

    if (customerAvgSpendingsError) {
      Snackbar.showSnackbar(customerAvgSpendingsError, "error");
    }

    if (customerActivitiesByDayError) {
      Snackbar.showSnackbar(customerActivitiesByDayError, "error");
    }

    if (customerOutstandingError) {
      Snackbar.showSnackbar(customerOutstandingError, "error");
    }
  }, [
    customerInsightsError,
    customerLatestBillDetailsError,
    customerAvgSpendingsError,
    customerActivitiesByDayError,
    customerOutstandingError,
  ]);

  const handleOpenPdf = (report: string) => {
    setSelectedReport(report);
    setOpenPdfModal(true);
  };

  const handleClosePdfModal = () => {
    setOpenPdfModal(false);
    setSelectedReport(null);
  };

  const navigate = useNavigate();
  const { customer } = useAppSelector((state) => state.customer);

  const firstPieData = {
    labels: ["Dry Clean", "Laundry", "Household", "Alteration"],
    datasets: [
      {
        label: "Service Usage",
        data: [
          customerCurrentYearServiceUsage?.find(
            (item) => item.serviceName === "Dry Clean"
          )?.count || 0,
          customerCurrentYearServiceUsage?.find(
            (item) => item.serviceName === "Laundry"
          )?.count || 0,
          customerCurrentYearServiceUsage?.find(
            (item) => item.serviceName === "Household"
          )?.count || 0,
          customerCurrentYearServiceUsage?.find(
            (item) => item.serviceName === "Alteration"
          )?.count || 0,
        ],
        backgroundColor: ["#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      },
    ],
  };

  const secondPieData = {
    labels: ["Dry Clean", "Laundry", "Household", "Alteration"],
    datasets: [
      {
        label: "Service Usage",
        data: [
          customerAllTimeServiceUsage?.find(
            (item) => item.serviceName === "Dry Clean"
          )?.count || 0,
          customerAllTimeServiceUsage?.find(
            (item) => item.serviceName === "Laundry"
          )?.count || 0,
          customerAllTimeServiceUsage?.find(
            (item) => item.serviceName === "Household"
          )?.count || 0,
          customerAllTimeServiceUsage?.find(
            (item) => item.serviceName === "Alteration"
          )?.count || 0,
        ],
        backgroundColor: ["#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      },
    ],
  };

  return (
    <PosLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          gap: "0.57rem",
        }}
      >
        <Box
          sx={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
            gap: "1.78rem",
            height: "100%",
            maxHeight: "100% !important",
          }}
        >
          <CustomerDetailSection
            showAnalysisButton={true}
            showEditButton={true}
            showHistoryButton={true}
          />
          <Box
            sx={{
              width: "100%",
              height: "50.65rem",
              borderRadius: "0.71rem",
              background: Colors.BACKSHADE_GRAY,
              padding: "2.78rem 2.14rem",
              display: "flex",
              flexDirection: "column",
              gap: "2.28rem",
            }}
          >
            <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
              Customer Insights
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                rowGap: "1.16rem",
                gap: "0.89rem",
              }}
            >
              <Box
                sx={{
                  background: Colors.SECONDARY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "53.64rem",
                  color: Colors.WHITE,
                  display: "flex",
                  flexDirection: "column",
                  gap: "2.28rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                    fontWeight: "bold",
                  }}
                >
                  Last Visit
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1.14rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.71rem",
                        lineHeight: "100%",
                      }}
                    >
                      Date
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.71rem", lineHeight: "100%" }}
                    >
                      {customerLatestBillDetails?.createdDate
                        ? formatUSDate(
                            new Date(customerLatestBillDetails.createdDate)
                          )
                        : "N/A"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1.14rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.71rem",
                        lineHeight: "100%",
                      }}
                    >
                      Payment
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.71rem", lineHeight: "100%" }}
                    >
                      {customerLatestBillDetails?.paymentAmount === null
                        ? "-"
                        : customerLatestBillDetails?.paymentAmount !== undefined
                        ? formatCurrency({
                            amount: customerLatestBillDetails.paymentAmount,
                          })
                        : "N/A"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1.14rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.71rem",
                        lineHeight: "100%",
                      }}
                    >
                      Number of Jobs
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.71rem", lineHeight: "100%" }}
                    >
                      {customerLatestBillDetails?.numberOfBillItems || "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  background: Colors.PRIMARY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.WHITE,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Since
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {customerInsights?.creationMonthYear || "N/A"}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Total Spend
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {customerInsights?.totalBillAmount !== undefined
                    ? formatCurrency({
                        amount: customerInsights.totalBillAmount,
                      })
                    : "N/A"}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Total Jobs
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {customerInsights?.totalBillItemCount || "N/A"}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Weekly Avg
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {customerAvgSpendings?.weeklyAverageSpending !== undefined
                    ? formatCurrency({
                        amount: customerAvgSpendings.weeklyAverageSpending,
                      })
                    : "N/A"}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Monthly Avg
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {customerAvgSpendings?.monthlyAverageSpending !== undefined
                    ? formatCurrency({
                        amount: customerAvgSpendings.monthlyAverageSpending,
                      })
                    : "N/A"}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: Colors.ACCENT_GRAY,
                  borderRadius: "0.85rem",
                  padding: "1.42rem",
                  height: "12.19rem",
                  boxSizing: "border-box",
                  width: "17.26rem",
                  color: Colors.BLACK,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.14rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.71rem",
                    lineHeight: "100%",
                  }}
                >
                  Yearly Avg
                </Typography>
                <Typography sx={{ fontSize: "1.71rem", lineHeight: "100%" }}>
                  {customerAvgSpendings?.yearlyAverageSpending !== undefined
                    ? formatCurrency({
                        amount: customerAvgSpendings.yearlyAverageSpending,
                      })
                    : "N/A"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "0.14rem", background: Colors.ACCENT_GRAY }}></Box>
        <Box
          sx={{
            flex: 5,
            display: "flex",
            flexDirection: "column",
            gap: "3.71rem",
            position: "relative",
          }}
        >
          <TopRightButton
            variation="Back"
            onClick={() => navigate(`/pos/${customerId}`)}
          />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "2.57rem",
              lineHeight: "100%",
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            Customer Analysis Reports
          </Typography>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            sx={{
              marginTop: "4rem",
              marginBottom: 0,
              position: "absolute",
              zIndex: 10,
              "& .MuiTab-root": {
                background: Colors.BACKSHADE_GRAY,
                color: Colors.PRIMARY,
                height: "2.85714285714rem",
                minHeight: "2.85714285714rem",
                width: "10.6778571429rem",
                minWidth: "10.6778571429rem",
                maxWidth: "10.6778571429rem",
                padding: 0,
              },
              "& .Mui-selected": {
                background: Colors.PRIMARY,
                color: `${Colors.WHITE} !important`,
              },
            }}
          >
            <Tab
              label="Analysis"
              sx={{
                fontWeight: "bold",
                fontSize: "1.14285714286rem",
                fontFamily: "Sebino",
                textTransform: "none",
                borderTopLeftRadius: "0.71rem",

                borderBottom: 0,
              }}
            />
            <Tab
              label="Reports"
              sx={{
                fontWeight: "bold",
                fontSize: "1.14285714286rem",
                fontFamily: "Sebino",
                textTransform: "none",
                borderRadius: 0,
                borderBottom: 0,
              }}
            />

            <Tab
              label="Refunds & Claims"
              sx={{
                fontWeight: "bold",
                fontSize: "1.14285714286rem",
                fontFamily: "Sebino",
                textTransform: "none",
                borderRadius: 0,
                borderBottom: 0,
                borderTopRightRadius: "0.71rem",
              }}
            />
          </Tabs>

          {/* LARGE CONTAINER THAT HAS ALL RIGHT SIDE */}
          <Box
            sx={{
              background: Colors.BACKSHADE_GRAY,
              borderRadius: "0 0.71rem 0.71rem 0.71rem",
              padding: "2rem 1.07rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "0.71rem",
              rowGap: "1.21rem",
              marginTop: "0.5rem",
              position: "relative",
            }}
          >
            {selectedTab === 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "0.71rem",
                  rowGap: "1.21rem",
                }}
              >
                {/* LEFT TOP SECTION */}
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <Box
                    sx={{
                      background: Colors.WHITE,
                      borderRadius: "0.71rem",
                      width: "22.857rem",
                      maxHeight: "10.928rem",
                      height: "10.928rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: pxToRem(18.75),
                      padding: pxToRem(22.7),
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: pxToRem(20),
                        lineHeight: pxToRem(24),
                        fontWeight: "bold",
                        color: Colors.TEXT_GRAY_DARK,
                      }}
                    >
                      Total Outstanding
                    </Typography>
                    <Box
                      sx={{
                        background: Colors.BACKSHADE_GRAY,
                        fontSize: pxToRem(36),
                        lineHeight: pxToRem(43.2),
                        color: Colors.PRIMARY,
                        fontWeight: "bold",
                        borderRadius: pxToRem(10),
                        paddingInline: pxToRem(19),
                        paddingBlock: pxToRem(8),
                      }}
                    >
                      {formatCurrency({
                        amount: customerOutstanding?.outstandingAmount || 0,
                        prefix: "$",
                      })}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: Colors.WHITE,
                      borderRadius: "0.71rem",
                      width: "22.857rem",
                      maxHeight: "10.928rem",
                      height: "10.928rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: pxToRem(18.75),
                      padding: pxToRem(22.7),
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: pxToRem(20),
                        lineHeight: pxToRem(24),
                        fontWeight: "bold",
                        color: Colors.TEXT_GRAY_DARK,
                      }}
                    >
                      Store Credit Amount
                    </Typography>
                    <Box
                      sx={{
                        background: Colors.BACKSHADE_GRAY,
                        fontSize: pxToRem(36),
                        lineHeight: pxToRem(43.2),
                        color: Colors.PRIMARY,
                        fontWeight: "bold",
                        borderRadius: pxToRem(10),
                        paddingInline: pxToRem(19),
                        paddingBlock: pxToRem(8),
                      }}
                    >
                      {formatCurrency({
                        amount: customer?.storeCreditBalance || 0,
                        prefix: "$",
                      })}
                    </Box>
                  </Box>
                </Box>

                {/* RIGHT TOP SECTION */}
                <Box
                  sx={{
                    background: Colors.WHITE,
                    borderRadius: "0.71rem",
                    width: "22.857rem",
                    maxHeight: "22.857rem",
                    height: "22.857rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pie data={firstPieData} options={firstPieOptions} />
                </Box>

                <Box
                  sx={{
                    background: Colors.WHITE,
                    borderRadius: "0.71rem",
                    width: "22.857rem",
                    maxHeight: "22.857rem",
                    height: "22.857rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pie data={secondPieData} options={secondPieOptions} />
                </Box>

                {/* BOTTOM SECTION */}
                <Box
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "0.71rem",
                    width: "70rem",
                    maxHeight: "37.714rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "1rem",
                  }}
                >
                  <Divider sx={{ width: "90%", borderBottomWidth: 1, mt: 1 }} />

                  <VictoryChart
                    domainPadding={70}
                    width={800}
                    height={400}
                    theme={VictoryTheme.material}
                  >
                    <VictoryLegend
                      x={300}
                      y={10}
                      orientation="horizontal"
                      gutter={20}
                      data={[
                        {
                          name: "Check-ins",
                          symbol: {
                            fill: "#4CAF50",
                            type: "square",
                            radius: 5,
                          },
                        },
                        {
                          name: "Checkouts",
                          symbol: {
                            fill: "#2989FF",
                            type: "square",
                            radius: 5,
                          },
                        },
                      ]}
                    />

                    <VictoryAxis
                      tickValues={[
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                      ]}
                    />

                    <VictoryAxis
                      dependentAxis
                      style={{
                        axis: { stroke: "none" },
                        ticks: { stroke: "none" },
                        tickLabels: { fontSize: 12, fill: "#000" },
                      }}
                    />

                    <VictoryGroup offset={35}>
                      <VictoryBar
                        data={barChartData.value.filter(
                          (d) => d.name === "check-in"
                        )}
                        x="day"
                        y="value"
                        barWidth={28}
                        style={{ data: { fill: "#4CAF50" } }}
                      />
                      <VictoryBar
                        data={barChartData.value.filter(
                          (d) => d.name === "pickup"
                        )}
                        x="day"
                        y="value"
                        barWidth={28}
                        style={{ data: { fill: "#2989FF" } }}
                      />
                    </VictoryGroup>
                  </VictoryChart>

                  <Divider sx={{ width: "90%", borderBottomWidth: 1, mb: 1 }} />
                </Box>
              </Box>
            )}

            {selectedTab === 1 && (
              <>
                {/* Reports tab Top Container */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: Colors.WHITE,
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                    paddingLeft: "2rem",
                    borderRadius: "0.71rem",

                    width: "100%",
                    minHeight: "7.64285714286rem",
                    marginLeft: "2rem",
                    marginRight: "2rem",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: "18rem" }}
                  >
                    <Typography variant="body1" sx={{ mb: "0.5rem" }}>
                      Report Type
                    </Typography>

                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={selectedReportType}
                      onChange={(e) => setSelectedReportType(e.target.value)}
                      sx={{
                        bgcolor: "white",
                        height: "2.214rem",
                      }}
                      InputProps={{
                        sx: { height: "2.214rem" },
                      }}
                    >
                      {reportTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: "18rem" }}
                  >
                    <Typography variant="body1" sx={{ mb: "0.5rem" }}>
                      Select Date
                    </Typography>

                    <DatePicker
                      selectsRange={true}
                      startDate={startDate ?? undefined}
                      endDate={endDate ?? undefined}
                      onChange={(update: [Date | null, Date | null]) => {
                        setDateRange(update);
                      }}
                      isClearable={false}
                      customInput={
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  marginRight: 0,
                                  marginLeft: "-0.5",
                                }}
                              >
                                <CalendarTodayIcon
                                  sx={{
                                    fontSize: "1rem",
                                    marginRight: "0.8rem",
                                  }}
                                />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{
                                  marginRight: "-0.5",
                                }}
                              >
                                <KeyboardArrowDownIcon />
                              </InputAdornment>
                            ),
                            sx: { height: "2.214rem", bgcolor: "white" },
                          }}
                        />
                      }
                    />
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: "15rem", marginRight: "1rem", mr: -2 }}
                  >
                    <Button
                      sx={{
                        backgroundColor: "#018752",
                        width: 100,
                        height: 29,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#FFFFFF",
                        fontSize: 9,
                        pt: 1,
                        borderRadius: 1,
                        mt: 2,

                        "&:hover": {
                          backgroundColor: "#026c44",
                        },
                      }}
                    >
                      Generate
                    </Button>
                  </Box>
                </Box>

                <Box
                  sx={{
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    paddingTop: "1rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    borderRadius: "0.71rem",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      overflowY: "auto",
                      paddingRight: "1rem",
                      paddingLeft: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#515151",
                        pb: 2,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Search Results
                    </Typography>
                    {["Annual Expenditure Report - 2024.pdf"].map(
                      (report, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: Colors.WHITE,
                            borderRadius: "0.71rem",
                            padding: "1rem 2rem",
                            marginBottom: "1.5rem",
                            borderBottom: `4px solid ${Colors.BACKSHADE_GRAY}`,
                          }}
                        >
                          {/* Left: Report Name */}
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "1.25rem" }}
                          >
                            {report}
                          </Typography>

                          {/* Right: Action Buttons */}
                          <Box display="flex" gap="1rem">
                            <IconButton
                              sx={{
                                backgroundColor: Colors.WHITE,
                                border: `1px solid ${Colors.PRIMARY}`,
                                borderRadius: "0.5rem",
                                padding: "0.5rem",
                                width: "3.857rem",
                                height: "2.357rem",
                              }}
                              onClick={() => handleOpenPdf(report)}
                            >
                              <Payment
                                sx={{
                                  fontSize: "1.571rem",
                                  color: Colors.PRIMARY,
                                }}
                              />
                            </IconButton>
                            <IconButton
                              sx={{
                                backgroundColor: Colors.WHITE,
                                border: `1px solid ${Colors.PRIMARY}`,
                                borderRadius: "0.5rem",
                                padding: "0.5rem",
                                width: "3.857rem",
                                height: "2.357rem",
                              }}
                              onClick={() => handleOpenPdf(report)}
                            >
                              <FileOpenIcon
                                sx={{
                                  fontSize: "1.571rem",
                                  color: Colors.PRIMARY,
                                }}
                              />
                            </IconButton>

                            <IconButton
                              sx={{
                                backgroundColor: Colors.WHITE,
                                border: `1px solid ${Colors.PRIMARY}`,
                                borderRadius: "0.5rem",
                                padding: "0.5rem",
                                width: "3.857rem",
                                height: "2.357rem",
                              }}
                            >
                              <DownloadIcon
                                sx={{
                                  fontSize: "1.571rem",
                                  color: Colors.PRIMARY,
                                }}
                              />
                            </IconButton>

                            <IconButton
                              sx={{
                                backgroundColor: Colors.WHITE,
                                border: `1px solid ${Colors.PRIMARY}`,
                                borderRadius: "0.5rem",
                                padding: "0.5rem",
                                width: "3.857rem",
                                height: "2.357rem",
                              }}
                            >
                              <PrintIcon
                                sx={{
                                  fontSize: "1.571rem",
                                  color: Colors.PRIMARY,
                                }}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      )
                    )}

                    <Divider
                      sx={{
                        backgroundColorL: "#B3B3B3",

                        paddingBottom: 1,
                        mb: 1,
                      }}
                    />

                    {/* PDF Modal */}
                    {openPdfModal && (
                      <PdfViewModal
                        open={openPdfModal}
                        onClose={handleClosePdfModal}
                        reportName={selectedReport}
                        name="John Doe"
                        customerId="GH8267"
                        address="No. 37, O'Conner St, Bloomberg, UK"
                        contactNumber="+360 245 675 467"
                        total={567.5}
                        month="February - 2025"
                      />
                    )}
                  </Box>
                </Box>

                {/* Bottom Container for Table */}
                <Box
                  sx={{
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    paddingBottom: "1rem",
                    paddingLeft: "1.3rem",
                    paddingRight: "1rem",
                    borderRadius: "0.71rem",
                    width: "100%",
                    minHeight: "49.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#515151",
                      pb: 2,
                      pl: 1,
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    Reports - History
                  </Typography>
                  <Box
                    sx={{
                      height: "34.5rem",
                      overflowY: "auto",
                      overflowX: "hidden",
                      paddingRight: "1rem",
                      paddingLeft: "1rem",
                      "&::-webkit-scrollbar": { width: "0.5rem" },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: Colors.ACCENT_GRAY_DISABLED,
                        borderRadius: "0.71rem",
                        border: "2px solid transparent",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                        borderRadius: "0rem",
                        margin: "20rem",
                      },
                    }}
                  >
                    <TableContainer sx={{ width: "100%" }}>
                      <Table sx={{ width: "100%" }}>
                        <TableBody>
                          {[
                            "Daily Report - RP454.pdf",
                            "Daily Report - RP453.pdf",
                            "Daily Report - RP452.pdf",
                            "Daily Report - RP451.pdf",
                            "Daily Report - RP454.pdf",
                            "Daily Report - RP453.pdf",
                            "Daily Report - RP452.pdf",
                            "Daily Report - RP451.pdf",
                            "Daily Report - RP454.pdf",
                            "Daily Report - RP453.pdf",
                            "Daily Report - RP452.pdf",
                            "Daily Report - RP451.pdf",
                          ].map((report, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                width: "100%",
                                marginBottom: "1.5rem",
                                borderRadius: "0.71rem",
                                backgroundColor: Colors.WHITE,
                                borderBottom: `4px solid ${Colors.BACKSHADE_GRAY}`,
                                "&:last-child": {
                                  marginBottom: 0,
                                },
                              }}
                            >
                              <TableCell
                                sx={{
                                  padding: "1rem",
                                  paddingLeft: "2rem",
                                  width: "70%",
                                  borderBottom: "none",
                                  borderRadius: " 0.71rem",
                                  overflow: "hidden",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "1.25rem" }}
                                >
                                  {report}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  padding: "1rem",
                                  borderBottom: "none",
                                  borderRadius: " 0.71rem ",
                                }}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-evenly"
                                >
                                  <IconButton
                                    sx={{
                                      backgroundColor: Colors.WHITE,
                                      border: `1px solid ${Colors.PRIMARY}`,
                                      borderRadius: "0.5rem",
                                      padding: "0.5rem",
                                      width: "3.857rem",
                                      height: "2.357rem",
                                    }}
                                    onClick={() =>
                                      handleOpenPdf("Daily Report - RP454.pdf")
                                    }
                                  >
                                    <FileOpenIcon
                                      sx={{
                                        fontSize: "1.571rem",
                                        color: Colors.PRIMARY,
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      backgroundColor: Colors.WHITE,
                                      border: `1px solid ${Colors.PRIMARY}`,
                                      borderRadius: "0.5rem",
                                      padding: "0.5rem",
                                      width: "3.857rem",
                                      height: "2.357rem",
                                    }}
                                  >
                                    <DownloadIcon
                                      sx={{
                                        fontSize: "1.571rem",
                                        color: Colors.PRIMARY,
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      backgroundColor: Colors.WHITE,
                                      border: `1px solid ${Colors.PRIMARY}`,
                                      borderRadius: "0.5rem",
                                      padding: "0.5rem",
                                      width: "3.857rem",
                                      height: "2.357rem",
                                    }}
                                  >
                                    <PrintIcon
                                      sx={{
                                        fontSize: "1.571rem",
                                        color: Colors.PRIMARY,
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                                {/* Render the PDF modal */}
                                {openPdfModal && (
                                  <PdfViewModal
                                    open={openPdfModal}
                                    onClose={handleClosePdfModal}
                                    reportName={selectedReport}
                                    name="John Doe"
                                    customerId="GH8267"
                                    address="No. 37, O'Conner St, Bloomberg, UK"
                                    contactNumber="+360 245 675 467"
                                    total={567.5}
                                    month="February - 2025"
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </PosLayout>
  );
};

export default CustomerAnalysisScreen;
