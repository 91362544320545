import { CustomerSearchMode } from "../typings/enum/CustomerSearchMode";
import {
  BillFilterDateType,
  BillPaymentStatus,
  BillPickupStatus,
} from "../typings/enum/paymentTypes";

export const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = "http://192.168.8.158:8080";
// export const BASE_URL = "https://b267-2402-4000-1182-b432-e9fb-74a1-7b46-38d7.ap.ngrok.io"
// export const BASE_URL = "http://prestovaletdev-env.eba-gd225xxa.us-east-1.elasticbeanstalk.com";

// export const BASE_URL = "https://api.drycleanx.com";

// Auth endpoints
export const LOGIN_URL = `${BASE_URL}/auth/sign-in`;
export const SEARCH_CUSTOMER = (query: string) =>
  `${BASE_URL}/customer/search?query=${query}`;
export const CREATE_CUSTOMER = `${BASE_URL}/customer/create`;
export const UPDATE_CUSTOMER = `${BASE_URL}/customer/update`;
export const CHANGE_PASSWORD = `${BASE_URL}/auth/password/change`;
export const LOGIN_USERS = `${BASE_URL}/user/get-login-users`;

// Pricing endpoints
export const GET_ALL_CATEGORIES = `${BASE_URL}/category`;
export const GET_ALL_SECOND_SUB_CATEGORIES = `${BASE_URL}/second-subcategory`;
export const GET_ALL_SUB_CATEGORIES = (id: number) =>
  `${BASE_URL}/subcategory?category=${id}`;
export const GET_ALL_PRICINGS = (id: number) =>
  `${BASE_URL}/price?category=${id}`;
export const UPLOAD_PRICE_LIST = `${BASE_URL}/price/upload-prices`;

export const GET_CATEGORY_RELATIONSHIP = `${BASE_URL}/price/relationship/category`;
export const GET_CATEGORY_MATERIAL_RELATIONSHIP = `${BASE_URL}/price/relationship/category-material`;
export const GET_SUB_CATEGORY_MATERIAL_RELATIONSHIP = `${BASE_URL}/price/relationship/sub-category-material`;

export const GET_ALTERATION_SUB_CATEGORIES = `${BASE_URL}/alteration-sub-category`;
export const GET_ALL_ALTERATION_PRICE = `${BASE_URL}/alteration`;

export const GET_FABRIC_TYPES = (id: number) =>
  `${BASE_URL}/fabrics?category=${id}`;

// StoreCredit endpoints
export const ADD_STORE_CREDIT = `${BASE_URL}/store-credit/add`;
export const GET_STORE_CREDIT_HISTORY = (id: number) =>
  `${BASE_URL}/store-credit/customers/${id}/history`;
export const DELETE_STORE_CREDIT = (customerId: number, noteId: number) =>
  `${BASE_URL}/store-credit/customer/${customerId}/credit/${noteId}`;

// Customer Endpoints
export const GET_CUSTOMER_BY_ID = (id: string) => `${BASE_URL}/customer/${id}`;
// export const GET_CUSTOMER_BILLS_BY_ID = (id: string) =>
//   `${BASE_URL}/bill/customer/${id}`; // old one
export const GET_CUSTOMER_BILLS_BY_ID = (
  customerId: number,
  size: number,
  page: number,
  paymentFilterStatus?: BillPaymentStatus,
  filterDateType?: BillFilterDateType,
  startDate?: string,
  endDate?: string,
  pickingFilterStatus?: BillPickupStatus,
  invoiceNumber?: string
) =>
  `${BASE_URL}/bill/customer/${customerId}?size=${size}&page=${page}${
    invoiceNumber ? `&invoiceNumber=${invoiceNumber}` : ""
  }${paymentFilterStatus ? `&paymentFilterStatus=${paymentFilterStatus}` : ""}${
    filterDateType ? `&filterDateType=${filterDateType}` : ""
  }${startDate ? `&startDate=${startDate}` : ""}${
    endDate ? `&endDate=${endDate}` : ""
  }${pickingFilterStatus ? `&pickingFilterStatus=${pickingFilterStatus}` : ""}`;

export const GET_CUSTOMER_CART_INFO_BY_ID = (id: string) =>
  `${BASE_URL}/customer/${id}/cart-info`;

export const NEW_BILL = `${BASE_URL}/bill/new-bill`;
export const GET_BILL_BY_ID = (billId: string) => `${BASE_URL}/bill/${billId}`;

// Rack Endpoints
export const GET_ALL_RACKS = `${BASE_URL}/rack`;
export const ADD_RACK = GET_ALL_RACKS;
export const REGISTER_RACK_BILL = `${BASE_URL}/rack-bill-register`;
export const GET_ALL_RACK_BILL = `${BASE_URL}/rack-bill-register/get-all`;

// User Notes Endpoints
export const CREATE_AND_UPDATE_USER_NOTE = `${BASE_URL}/user-note`;
export const GET_USER_NOTES_BY_ID = (id: string) =>
  `${BASE_URL}/user-note/customer/${id}`;
export const DELETE_USER_NOTE = (id: string) => `${BASE_URL}/user-note/${id}`;

// Bill Notes Endpoints
export const CREATE_AND_UPDATE_BILL_NOTE = `${BASE_URL}/bill-note`;
export const GET_BILL_NOTES_BY_ID = (id: number) =>
  `${BASE_URL}/bill-note/bill/${id}`;
export const DELETE_BILL_NOTE = (id: string) => `${BASE_URL}/bill-note/${id}`;

// Discount endpoints
export const GET_ALL_STORE_DISCOUNTS = (id: number) =>
  `${BASE_URL}/discount/store/${id}`;
export const CREATE_DISCOUNT = `${BASE_URL}/discount`;
export const DELETE_DISCOUNT = (id: string) => `${BASE_URL}/discount/${id}`;
export const UPDATE_DISCOUNT = `${BASE_URL}/discount`;

// Merchant endpoints
export const GET_MERCHANT_DETAILS = (whiteLabel: string) =>
  `${BASE_URL}/merchant/${whiteLabel}`;
export const GET_MERCHANT_STORES = (whiteLabel: string) =>
  `${BASE_URL}/merchant/${whiteLabel}/stores`;
export const GET_ALL_MERCHANTS = `${BASE_URL}/merchant/all`;

// Payment endpoints
export const ADD_PAYMENT = `${BASE_URL}/payment/create`;

// store endpoints
export const ADD_STORE_CONFIG = `${BASE_URL}/store-config`;
export const GET_STORE_CONFIG = (storeId: number, merchantId: number) =>
  `${BASE_URL}/store-config/merchant/${merchantId}/store/${storeId}`;

// Pintag endpoints
export const GET_ALL_COLOR_PINTAGS = `${BASE_URL}/pintag-color`;
export const CREATE_COLOR_PINTAG = GET_ALL_COLOR_PINTAGS;
export const UPDATE_COLOR_PINTAG = GET_ALL_COLOR_PINTAGS;
export const DELETE_COLOR_PINTAG = (id: number) =>
  `${BASE_URL}/pintag-color/${id}`;
export const UPDATE_COLOR_PINTAG_ORDER = `${BASE_URL}/pintag-color/reorder`;

export const GET_ALL_SERVICE_PINTAGS = `${BASE_URL}/service-pintag`;
export const CREATE_SERVICE_PINTAG = GET_ALL_SERVICE_PINTAGS;
export const UPDATE_SERVICE_PINTAG = GET_ALL_SERVICE_PINTAGS;
export const DELETE_SERVICE_PINTAG = (id: number) =>
  `${BASE_URL}/service-pintag/${id}`;

// damage endpoints
export const GET_ALL_DAMAGES = `${BASE_URL}/damage/merchant/1/store/1`;

//instructions endpoints
export const GET_ALL_INSTRUCTIONS = `${BASE_URL}/instruction/merchant/1/store/1`;

//spots endpoints
export const GET_ALL_SPOTS = `${BASE_URL}/spot/store/1/merchant/1`;

//upcharges endpoints
export const GET_ALL_UPCHARGES = `${BASE_URL}/upcharge/store/1/merchant/1`;

//customer insights endpoints
export const GET_CUSTOMER_INSIGHTS = (id: number) =>
  `${BASE_URL}/insights/customer/${id}/overview`;

//customer latest bill details endpoints
export const GET_CUSTOMER_LATEST_BILL_DETAILS = (id: number) =>
  `${BASE_URL}/insights/customer/${id}/latest-bill`;

//customer average spendings endpoints
export const GET_CUSTOMER_AVG_SPENDINGS = (id: number) =>
  `${BASE_URL}/insights/customer/${id}/spending-analytics`;

export const GET_CUSTOMER_INTERACTIONS_BY_DAY = (id: number) =>
  `${BASE_URL}/insights/customer/${id}/average-activity`;

export const GET_CUSTOMER_OUTSTANDING = (id: number) =>
  `${BASE_URL}/insights/customer/${id}/outstanding-amount`;

//customer current year service usage chart endpoints
export const GET_CUSTOMER_CURRENT_YEAR_SERVICE_USAGE = (id: number) =>
  `${BASE_URL}/insights/customer/${id}/service-usage/current-year`;

//customer all time service usage chart endpoints
export const GET_CUSTOMER_ALL_TIME_SERVICE_USAGE = (id: number) =>
  `${BASE_URL}/insights/customer/${id}/service-usage/all-time`;

//admin customization endpoints
export const ADD_UPCHARGE = (merchantId: number, storeId: number) =>
  `${BASE_URL}/upcharge/store/${storeId}/merchant/${merchantId}`;

export const UPDATE_UPCHARGE = (merchantId: number, storeId: number) =>
  `${BASE_URL}/upcharge/store/${storeId}/merchant/${merchantId}`;

export const DELETE_UPCHARGE = (
  merchantId: number,
  storeId: number,
  upChargeId: number
) =>
  `${BASE_URL}/upcharge/${upChargeId}/store/${storeId}/merchant/${merchantId}`;

export const DELETE_UPCHARGE_VARIANT = (
  merchantId: number,
  storeId: number,
  upChargeId: number,
  variantId: number
) =>
  `${BASE_URL}/upcharge/store/${storeId}/merchant/${merchantId}/upcharge/${upChargeId}/variant/${variantId}`;

//admin customization endpoints
export const ADD_SPOT = (merchantId: number, storeId: number) =>
  `${BASE_URL}/spot/store/${storeId}/merchant/${merchantId}`;

export const UPDATE_SPOTS = (merchantId: number, storeId: number) =>
  `${BASE_URL}/spot/store/${storeId}/merchant/${merchantId}`;

export const DELETE_SPOT = (
  spotId: number,
  storeId: number,
  merchantId: number
) => `${BASE_URL}/spot/${spotId}/store/${storeId}/merchant/${merchantId}`;

//admin customization endpoints
export const ADD_INSTRUCTIONS = (merchantId: number, storeId: number) =>
  `${BASE_URL}/instruction/merchant/${merchantId}/store/${storeId}`;

export const DELETE_INSTRUCTION = (
  merchantId: number,
  storeId: number,
  instructionId: number
) => `${BASE_URL}/instruction/${storeId}/${merchantId}/${instructionId}`;

export const UPDATE_INSTRUCTION = (merchantId: number, storeId: number) =>
  `${BASE_URL}/instruction/merchant/${merchantId}/store/${storeId}`;

//cashier onbooarding endpoints
// export const CASHIER_ONBOARDING = `${BASE_URL}/station/request-new`;
// export const OPEN_CASHIER = `${BASE_URL}/testing-123/open`;
// // export const OPEN_CASHIER = `${BASE_URL}/cashier-point/station/open`;
// export const GET_STATION_ACCESS = (cashierId: number) =>
//   `${BASE_URL}/station/access-status/${cashierId}`;
// export const GET_CASHIER_STATION_LIST = `${BASE_URL}/station`;
// export const GRANT_STATION_ACCESS = (stationId: number) =>
//   `${BASE_URL}/station/${stationId}/grant-access`;
export const NEW_STATION = `${BASE_URL}/station/request-new`;
export const GET_ALL_STATIONS = `${BASE_URL}/station`;
export const GET_GRANTED_STATIONS = `${BASE_URL}/station/access-granted`;
export const GET_DENIED_STATIONS = `${BASE_URL}/station/access-denied`;
export const GET_STATION_BY_ID = (id: number) =>
  `${BASE_URL}/station/${id}/info`;
export const UPDATE_STATION_ACCESS = (id: number, status: "grant" | "deny") =>
  `${BASE_URL}/station/${id}/${status}-access`;

export const OPEN_STATION_CASHIER = `${BASE_URL}/station/open`;
export const GET_STATION_CASHIER_SUMMARY = (id: number) =>
  `${BASE_URL}/station/day-end/station-summary/${id}`;
export const GET_ALL_STATIONS_DAY_END_SUMMARY = (
  storeId: number,
  date?: string
) =>
  `${BASE_URL}/station/day-end/station-summary?storeId=${storeId}${
    date ? `&date=${date}` : ""
  }`;
export const GET_STATION_SUMMARY = (id: number) =>
  `${BASE_URL}/station/summary/${id}`;
export const GET_ALL_STATION_SUMMARY = `${BASE_URL}/station/summary`;
export const ADD_CASH = `${BASE_URL}/station/add-cash`;
export const REMOVE_CASH = `${BASE_URL}/station/remove-cash`;
export const GET_STORE_SUMMARY = (storeId: number) =>
  `${BASE_URL}/station/day-end/store-summary?storeId=${storeId}`;
export const CLOSE_STATION_CASHIER = `${BASE_URL}/station/close`;
export const END_DAY = `${BASE_URL}/station/new/day-end/station-summary`;

export const LATEST_CASHIER_SESSION = (stationId: number) =>
  `${BASE_URL}/station/${stationId}/latest-session/summary`;

//admin customization endpoints
export const ADD_DAMAGE = (merchantId: number, storeId: number) =>
  `${BASE_URL}/damage/merchant/${merchantId}/store/${storeId}`;

export const DELETE_DAMAGE = (
  merchantId: number,
  storeId: number,
  damageId: number
) => `${BASE_URL}/damage/${damageId}/merchant/${merchantId}/store/${storeId}`;

export const UPDATE_DAMAGE = (merchantId: number, storeId: number) =>
  `${BASE_URL}/damage/merchant/${merchantId}/store/${storeId}`;

export const PICKUP_ITEM = `${BASE_URL}/pickup-items`;
export const GET_BILL_ITEM_IDS = (billId: number) =>
  `${BASE_URL}/bill/${billId}/items`;

export const INVOICE_HISTORY = (billId: number) =>
  `${BASE_URL}/invoice-history/${billId}`;

export const VOID_INVOICE = (billId: number) =>
  `${BASE_URL}/bill/${billId}/void`;
export const BILL_PAYMENT_HISTORY = `${BASE_URL}/payment/filter`;
