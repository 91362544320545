import { FC } from "react";
import { Box, Typography } from "@mui/material";

import CustomModal from "../../../common/custom-modal/custom-modal";
import { CustomButton } from "../../../atoms";
import { pxToRem } from "../../../../util";
import { Colors } from "../../../../configs";

interface ExitConfirmationModalProps {
  open: boolean;
  title: string;
  message: string;
  saveButtonText?: string;
  discardButton?: {
    text: string;
    onClick: () => void;
  };
  onCancel: () => void;
  onConfirm: () => void;
}

const ExitConfirmationModal: FC<ExitConfirmationModalProps> = ({
  open,
  title,
  message,
  saveButtonText,
  discardButton,
  onCancel,
  onConfirm,
}) => {
  return (
    <CustomModal
      openModal={open}
      onClose={onCancel}
      title={title}
      width={pxToRem(564)}
      height={pxToRem(420)}
      mode="modal"
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: pxToRem(100) }}>
        <Typography
          sx={{
            fontSize: pxToRem(24),
            color: Colors.MAIN_GRAY,
            marginTop: pxToRem(20),
          }}
        >
          {message}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: pxToRem(25),
            flexWrap: "wrap",
          }}
        >
          {discardButton && (
            <CustomButton
              onClick={discardButton.onClick}
              sx={{
                width: pxToRem(216),
                minWidth: pxToRem(216),
                maxWidth: pxToRem(216),
                height: pxToRem(64),
                fontSize: pxToRem(24),
                background: "none",
                border: `${pxToRem(1)} solid ${Colors.PRIMARY}`,
                borderRadius: pxToRem(5),
                color: Colors.PRIMARY,
                textTransform: "none",
                padding: "0",
              }}
            >
              {discardButton.text}
            </CustomButton>
          )}

          <CustomButton
            onClick={onConfirm}
            sx={{
              width: pxToRem(216),
              minWidth: pxToRem(216),
              maxWidth: pxToRem(216),
              height: pxToRem(64),
              fontSize: pxToRem(24),
              borderRadius: pxToRem(5),
              textTransform: "none",
              padding: "0",
            }}
          >
            {saveButtonText}
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default ExitConfirmationModal;
