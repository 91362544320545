import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { VOID_INVOICE } from "../../configs";
import { BaseResponse } from "../../typings";
import { RefundType } from "../../typings/enum/RefundType";
import { IBillVoidResponse } from "../../typings/interfaces/responses/void-invoice-response";

interface IRefund {
  refundItems: {
    billId: number;
    refundMethod: RefundType;
  }[];
  customerId: number;
  cashierId: number;
  stationAccessSessionId: number;
  managerId: number;
  reason: string;
}

interface IVoidInvoicePayload {
  billId: number;
  voidReason: string;
  cashierId: string | number;
  refund?: IRefund;
}

export const voidInvoiceAction = createAsyncThunk(
  "cart/voidInvoice",
  async (payload: IVoidInvoicePayload, { rejectWithValue }) => {
    try {
      const response = await axios.put<BaseResponse<IBillVoidResponse | null>>(
        VOID_INVOICE(payload.billId),
        {
          voidReason: payload.voidReason,
          cashierId: payload.cashierId,
          ...(payload.refund ? { refund: payload.refund } : {}),
        }
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);
