import { createSlice } from "@reduxjs/toolkit";
import { BaseResponse } from "../../typings";
import { voidInvoiceAction } from "../actions/voidInvoiceAction";
import { IBillVoidResponse } from "../../typings/interfaces/responses/void-invoice-response";

interface VoidInvoiceState {
  voidInvoiceLoading: boolean;
  voidInvoiceError: string | null;
  voidInvoiceResponse: BaseResponse<IBillVoidResponse | null> | null;
}

const initialState: VoidInvoiceState = {
  voidInvoiceLoading: false,
  voidInvoiceError: null,
  voidInvoiceResponse: null,
};

const voidInvoiceSlice = createSlice({
  name: "voidInvoice",
  initialState,
  reducers: {
    resetVoidInvoiceState: (state) => {
      state.voidInvoiceLoading = false;
      state.voidInvoiceError = null;
      state.voidInvoiceResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(voidInvoiceAction.pending, (state) => {
        state.voidInvoiceLoading = true;
        state.voidInvoiceError = null;
        state.voidInvoiceResponse = null;
      })
      .addCase(voidInvoiceAction.fulfilled, (state, action) => {
        state.voidInvoiceLoading = false;

        if (action.payload?.status === "FAILED") {
          state.voidInvoiceError = action.payload.message || "Void failed";
          state.voidInvoiceResponse = null;
        } else {
          state.voidInvoiceError = null;
          state.voidInvoiceResponse = action.payload;
        }
      })
      .addCase(voidInvoiceAction.rejected, (state, action) => {
        state.voidInvoiceLoading = false;
        state.voidInvoiceError = action.error.message ?? "Something went wrong";
        state.voidInvoiceResponse = null;
      });
  },
});

export const { resetVoidInvoiceState } = voidInvoiceSlice.actions;
export default voidInvoiceSlice.reducer;
