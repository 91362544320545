import { FC, MouseEvent } from "react";
import StyledTreeItem from "./styledTreeItem";
import { Box, IconButton, Typography } from "@mui/material";
import { Colors } from "../../../configs/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatCurrency } from "../../../util/common-functions";

export interface ITreeChildNodeProps {
  nodeID: string;
  instructions?: string[];
  items: (string | undefined)[];
  upcharges?: string[];
  qty: number;
  total: number;
}

const TreeChildNode: FC<ITreeChildNodeProps> = ({
  nodeID,
  instructions,
  items,
  upcharges,
  qty,
  total,
}) => {
  const handleComponentClick = (event: MouseEvent) => {
    // Prevent the click event from propagating and affecting TreeView
    event.stopPropagation();
  };
  return (
    <StyledTreeItem
      nodeId={nodeID}
      label={
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1.53857143rem",
              minWidth: "52.1428571rem",
              maxWidth: "52.1428571rem",
            }}
          >
            <IconButton onClick={handleComponentClick}>
              <DeleteIcon />
            </IconButton>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  lineHeight: "1.2rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "46.4285714rem",
                  whiteSpace: "nowrap",
                }}
              >
                {items
                  .map((item, index) => {
                    if (item === undefined) {
                      return;
                    }
                    return item;
                  })
                  .join(", ")}
                {(instructions?.length || 0) > 0
                  ? "&nbsp; + Instructions-"
                  : ""}{" "}
                {instructions?.map((instruction, index) => {
                  return (
                    <>
                      {instruction}
                      {index < instructions.length - 1 ? ", " : ""}
                    </>
                  );
                })}
              </Typography>
              <Typography
                sx={{
                  // "&::before": { content: `"+ "` },
                  fontSize: "1rem",
                  lineHeight: "1.2rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "46.4285714rem",
                  whiteSpace: "nowrap",
                }}
              >
                {(upcharges?.length || 0) > 0 ? "&nbsp; + Upcharges-" : ""}{" "}
                {upcharges?.map((upcharge, index) => {
                  return (
                    <>
                      {upcharge}
                      {index < upcharges.length - 1 ? ", " : ""}
                    </>
                  );
                })}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: "1rem",
              lineHeight: "1.2rem",
              marginRight: "7rem",
              marginLeft: "auto",
            }}
          >
            x{qty}
          </Typography>
          <Typography
            sx={{
              fontSize: "1.14285714rem",
              lineHeight: "1.37142857rem",
              width: "6rem",
              textAlign: "right",
            }}
          >
            {formatCurrency({
              amount: total,
              prefix: "$",
            })}
          </Typography>
        </Box>
      }
      type="child"
      sx={{
        "& > .MuiTreeItem-content": {
          background: Colors.WHITE,
          width: "calc(100% - 2.21428571rem)",
          marginLeft: "2.21428571rem",
          height: "3.85714286rem",
          borderRadius: "0.428571429rem",
          display: "flex",
          alignItems: "center",
          py: "0.75rem",
          px: "1.2696428571428rem",
          pl: "0rem",
          boxSizing: "border-box",
          border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
          "&:hover": {
            background: Colors.ACCENT_GRAY,
          },
          my: "0.142857143rem",
        },
      }}
    />
  );
};

export default TreeChildNode;
