import React, { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import { he } from "@faker-js/faker";
import dayjs, { Dayjs } from "dayjs";
import { pad } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import CustomModal from "../../common/custom-modal/custom-modal";
import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import CustomDateTimePicker from "../../atoms/custom-date-time-picker/custom-date-time-picker";
import { pxToRem } from "../../../util";

export interface TaxExceptionModalProps {
  open: boolean;
  onClose(): void;
}

const TaxExceptionModal: FC<TaxExceptionModalProps> = ({ open, onClose }) => {
  const [taxID, setTaxID] = useState("");
  const [expiryDate, setExpiryDate] = useState<Dayjs | null>(null);

  const schema = yup.object().shape({
    taxID: yup.string().required("Tax ID is required"),
    expiryDate: yup
      .date()
      .nullable()
      .typeError("Expiry Date is required")
      .required("Expiry Date is required"),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  return (
    <CustomModal
      title={"Tax Exemption"}
      openModal={open}
      width={pxToRem(564)}
      height={pxToRem(430)}
      onClose={onClose}
    >
      <Box
        sx={{
          paddingTop: "2rem",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography
            sx={{
              color: Colors.TEXT_GRAY_DARK,
              fontSize: pxToRem(20),
              minWidth: pxToRem(100),
            }}
          >
            Tax ID
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
            <Controller
              name="taxID"
              control={control}
              defaultValue=""
              render={({ field, fieldState }) => (
                <CustomTextBox
                  {...field}
                  sx={{ width: pxToRem(308), height: pxToRem(39) }}
                  placeholder="Tax ID here"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  inputProps={{
                    style: {
                      fontSize: pxToRem(20),
                      height: pxToRem(39),
                      padding: "0 1rem",
                    },
                  }}
                />
              )}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography
            sx={{ color: Colors.TEXT_GRAY_DARK, fontSize: pxToRem(20) }}
          >
            Exp Date
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
            <Box sx={{ display: "flex", marginRight: "0.7rem", alignItems: "center" }}>
              <CustomDateTimePicker
                type="date"
                value={expiryDate}
                onChange={(date) =>
                  setValue(
                    "expiryDate",
                    date ? (date.toDate() as Date) : new Date(),
                    {
                      shouldValidate: true,
                    }
                  )
                }
                sx={{
                  width: pxToRem(308),
                  height: pxToRem(39),
                  "& input": {
                    fontSize: pxToRem(20),
                    height: pxToRem(39),
                    py: "1rem",
                    pl: "1.5rem",
                    minWidth: pxToRem(308),
                    width: pxToRem(308),
                    boxSizing: "border-box",
                  },
                  "& fieldset": {
                    width: pxToRem(308),
                    padding: 0,
                  },
                }}
              />
            </Box>

            {errors.expiryDate && (
              <Box>
                <Typography
                  color="error"
                  sx={{ fontSize: pxToRem(12), marginLeft: pxToRem(22) }}
                >
                  {errors.expiryDate.message}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "2.5rem",
          }}
        >
          <CustomButton
            sx={{
              height: pxToRem(64),
              // width: "14.2857143rem",
              minWidth: pxToRem(467),
              flex: 1,
              fontSize: "1.42857143rem",
              fontWeight: "bold",
              padding: "0",
            }}
            onClick={() => {
              onClose(); //ToDo: When the modal closes via close button, the input fields should be cleared.
            }}
            disabled={!isValid}
          >
            Add Exemption
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default TaxExceptionModal;
