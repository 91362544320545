import { FC, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Tabs,
  Tab,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import CustomModal from "../../../components/common/custom-modal/custom-modal";
import { CustomButton, CustomTextBox } from "../../../components";
import { formatCurrency, pxToRem } from "../../../util";
import { Colors } from "../../../configs/colors";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { voidInvoiceAction } from "../../../redux/actions/voidInvoiceAction";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { resetVoidInvoiceState } from "../../../redux/slice/voidInvoiceSlice";
import { RefundType } from "../../../typings/enum/RefundType";
import { useParams } from "react-router-dom";
import { CustomSelectBox } from "../../../components/atoms/custom-select-box/custom-select-box";
import { paymentTypeLabels } from "../../../typings/enum/paymentTypes";

interface IVoidInvoiceModalProps {
  open: boolean;
  onClose: () => void;
}

const VoidInvoiceModal: FC<IVoidInvoiceModalProps> = ({ open, onClose }) => {
  const [reason, setReason] = useState("");
  const [voidTypeIndex, setVoidTypeIndex] = useState<number>(0);
  const [refundMethod, setRefundMethod] = useState<RefundType>(
    RefundType.STORE_CREDIT
  );

  const params = useParams();
  const snackbar = useSnackbarContext();
  const { selectedSession, cartHistorySelectedRows, billPaymentDetails } =
    useAppSelector((state) => state.cart);
  const { voidInvoiceResponse, voidInvoiceError, voidInvoiceLoading } =
    useAppSelector((state) => state.voidInvoice);
  const { activeStation, activeStationCashier } = useAppSelector(
    (state) => state.cashierStation
  );
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const customerId = params["id"];
  const cashierId = user?.id;

  useEffect(() => {
    if (voidInvoiceResponse?.status === "SUCCESS") {
      snackbar.showSnackbar("Invoice voided successfully", "success");
      dispatch(resetVoidInvoiceState());
      handleClose();
    } else if (voidInvoiceResponse?.status === "FAILED" || voidInvoiceError) {
      const errorMsg =
        voidInvoiceResponse?.message ||
        voidInvoiceError ||
        "Something went wrong";
      snackbar.showSnackbar(errorMsg, "error");

      dispatch(resetVoidInvoiceState());
    }
  }, [voidInvoiceResponse, voidInvoiceError]);

  if (!customerId) {
    snackbar.showSnackbar("Customer not found", "error");
    return null;
  }
  if (!cashierId) {
    snackbar.showSnackbar("Cashier not found", "error");
    return null;
  }
  if (!activeStation?.id) {
    snackbar.showSnackbar("Station not found", "error");
    return null;
  }
  if (!activeStationCashier?.stationAccessId) {
    snackbar.showSnackbar("Station Session not found", "error");
    return null;
  }
  const parsedCustomerId = Number(customerId);

  const handleClose = () => {
    setReason("");
    setVoidTypeIndex(0);
    setRefundMethod(RefundType.STORE_CREDIT);
    onClose();
  };

  if (
    (!cartHistorySelectedRows || cartHistorySelectedRows?.length === 0) &&
    open
  ) {
    snackbar.showSnackbar("No session selected", "error");
    handleClose();
  }
  if (!selectedSession || cartHistorySelectedRows?.length === 0) {
    return null;
  }

  const bill = cartHistorySelectedRows[0];
  const billId: number = bill.id;
  const invoiceNumber: string = bill.billId;

  const handleVoidInvoice = () => {
    const basePayload = {
      billId,
      voidReason: reason,
      cashierId: cashierId,
    };

    const refundPayload = {
      ...basePayload,
      ...(voidTypeIndex === 1
        ? {
            refund: {
              refundItems: [
                {
                  billId,
                  refundMethod: refundMethod,
                },
              ],
              customerId: parsedCustomerId,
              cashierId: cashierId,
              stationAccessSessionId: activeStationCashier.stationAccessId,
              managerId: 2, // to do: update later after implementing manager access
              reason: "Voiding invoice refund",
            },
          }
        : {}),
    };
    // isRefundingVoid
    //   ? {
    //       ...basePayload,
    //       refund: {
    //         refundItems: [
    //           {
    //             billId,
    //             refundMethod:
    //               refundMethod,
    //           },
    //         ],
    //         customerId: customerId || 0,
    //         cashierId: cashierId || 43,
    //         stationAccessSessionId: 26,
    //         managerId: 2,
    //         reason: "Voiding invoice refund",
    //       },
    //     }
    //   : basePayload;

    dispatch(voidInvoiceAction(refundPayload))
      .then((res) => {
        handleClose();
      })
      .catch((error) => {
        snackbar.showSnackbar("Error voiding invoice", "error");
      });
  };

  // const payments = [
  //   {
  //     method: "Cash",
  //     amount: 0,
  //   },
  //   {
  //     method: "Credit Card",
  //     amount: 0,
  //   },
  //   // {
  //   //   method: "Store Credit",
  //   //   amount: paidAmount,
  //   // },
  //   // {
  //   //   method: "Store Credit",
  //   //   amount: paidAmount,
  //   // },
  // ];

  const payments: {
    method: string;
    amount: number;
  }[] =
    (billPaymentDetails?.payments ?? []).length > 0
      ? billPaymentDetails?.payments
          .map((payment) => {
            // skip payments with paymentType LATER or MONTHLY
            if (
              payment.paymentType === "LATER" ||
              payment.paymentType === "MONTHLY"
            ) {
              return null;
            }

            return {
              method: paymentTypeLabels(payment.paymentType),
              amount: payment.amount,
            };
          })
          .filter(
            (payment): payment is { method: string; amount: number } =>
              payment !== null
          ) ?? []
      : [
          {
            method: "No Payments",
            amount: 0,
          },
        ];

  return (
    <CustomModal
      title="Void Invoice"
      openModal={open}
      width={pxToRem(550)}
      height={pxToRem(
        voidTypeIndex === 0
          ? 448
          : refundMethod === RefundType.STORE_CREDIT
          ? 574
          : payments.length === 1
          ? 574
          : payments.length === 2
          ? 600
          : payments.length === 3
          ? 630
          : 650
      )}
      onClose={onClose}
    >
      {/* Modal Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: pxToRem(20),
        }}
      >
        {/* Tabs */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              justifyContent: "flex-start",
              display: "flex",
              padding: "0",
              maxHeight: pxToRem(44),
              borderBottom: `${pxToRem(1.5)} solid ${
                Colors.ACCENT_GRAY_DISABLED
              }`,
            }}
          >
            <Tabs
              value={voidTypeIndex}
              onChange={(e, newValue) => setVoidTypeIndex(newValue)}
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "& .MuiTab-root": {
                  background: Colors.BACKSHADE_GRAY,
                  color: Colors.PRIMARY,
                  height: pxToRem(43),
                  minHeight: pxToRem(43),
                  width: "11.9285714286rem",
                  whiteSpace: "nowrap",
                  textTransform: "none",
                  fontWeight: "medium",
                },
                "& .Mui-selected": {
                  background: Colors.PRIMARY,
                  color: `${Colors.WHITE} !important`,
                },
                "& .MuiTab-root:first-of-type": {
                  borderRadius: "0.71rem 0 0 0",
                },
                "& .MuiTab-root:nth-of-type(2)": {
                  borderRadius: "0 0.71rem 0 0",
                },
              }}
            >
              <Tab
                label="No Refunding Void"
                sx={{ fontWeight: "bold", fontSize: "1.142rem" }}
              />
              <Tab
                label="Refunding Void"
                sx={{ fontWeight: "bold", fontSize: "1.142rem" }}
              />
            </Tabs>
          </Box>
        </Box>

        {/* Content Changed by tabs */}
        {voidTypeIndex === 1 && (
          <Box
            sx={{
              px: pxToRem(6),
              overflowY: `auto`,
              overflowX: `hidden`,
              pt: pxToRem(10),

              "&::-webkit-scrollbar": {
                width: "0.3rem",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#ADADAD",
                borderRadius: "0rem",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
                borderRadius: "0rem",
              },
            }}
          >
            <Box
              sx={{
                padding: "0",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: pxToRem(12),
              }}
            >
              {/* Refund Method */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexWrap: "nowrap",
                  width: "100%",
                  gap: pxToRem(25),
                }}
              >
                <Typography
                  sx={{
                    fontSize: pxToRem(18),
                    color: Colors.MAIN_GRAY,
                    whiteSpace: "nowrap",
                    width: pxToRem(200),
                  }}
                >
                  Refund Method:
                </Typography>
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <CustomSelectBox
                    value={refundMethod}
                    onChange={(e) =>
                      setRefundMethod(e.target.value as RefundType)
                    }
                    width="100%"
                  >
                    <MenuItem value={RefundType.STORE_CREDIT}>
                      Refund to Store Credits
                    </MenuItem>
                    <MenuItem value={RefundType.SAME_METHOD}>
                      Refund to Same Method
                    </MenuItem>
                  </CustomSelectBox>
                </Box>
              </Box>

              {/* Store Credit Refund Amount */}
              {refundMethod === RefundType.STORE_CREDIT && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexWrap: "nowrap",
                    width: "100%",
                    gap: pxToRem(25),
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(18),
                      color: Colors.MAIN_GRAY,
                      whiteSpace: "nowrap",
                      width: pxToRem(200),
                    }}
                  >
                    Store Credit Amount:
                  </Typography>
                  <Box
                    sx={{
                      flexGrow: 1,
                      pl: pxToRem(7),
                    }}
                  >
                    <CustomTextBox
                      value={formatCurrency({
                        amount: billPaymentDetails?.totalAmount ?? 0,
                        prefix: "$",
                      })}
                      disabled={true}
                      sx={{
                        width: `calc(100% - ${pxToRem(2)})`,
                        "& input": {
                          background: `${Colors.ACCENT_GRAY} !important`,
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}

              {/* Refund Amounts */}
              {refundMethod === RefundType.SAME_METHOD && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexWrap: "nowrap",
                    width: "100%",
                    gap: pxToRem(25),
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(18),
                      color: Colors.MAIN_GRAY,
                      whiteSpace: "nowrap",
                      width: pxToRem(200),
                    }}
                  >
                    Refund Options
                  </Typography>
                  <Box
                    sx={{
                      flexGrow: 1,
                      overflowY: "auto",
                      overflowX: "hidden",
                      maxHeight: pxToRem(110),
                      paddingRight: payments.length > 3 ? pxToRem(10) : 0,
                    }}
                  >
                    <ul
                      style={{
                        paddingInline: "0",
                        marginBlock: pxToRem(10),
                        paddingBlock: "0",
                        display: "flex",
                        flexDirection: "column",
                        gap: pxToRem(10),
                        fontSize: pxToRem(16),
                        color: Colors.TEXT_GRAY_DARK,
                      }}
                    >
                      {payments.map((payment, index) => (
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginLeft: 0,
                            paddingLeft: 0,
                          }}
                        >
                          <span>{payment.method ?? "Undefined"}:</span>{" "}
                          <strong>
                            {formatCurrency({
                              amount: payment.amount,
                              prefix: "$",
                            })}
                          </strong>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}

        {/* Bottom Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "2rem",
            py: "1rem",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.6rem" }}>
            <Typography
              sx={{
                fontSize: pxToRem(18),
                color: Colors.TEXT_GRAY_DARK,
              }}
            >
              Reason for voiding invoice number <strong>{invoiceNumber}</strong>
              :
            </Typography>
            <CustomTextBox
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  width: pxToRem(467),
                  height: pxToRem(69),
                  minHeight: pxToRem(69),
                  maxHeight: pxToRem(69),
                },
              }}
            />
          </Box>

          <CustomButton
            sx={{
              background: Colors.PRIMARY,
              width: `100%`,
              height: pxToRem(64),
              fontSize: pxToRem(24),
              mt: pxToRem(40),
            }}
            onClick={handleVoidInvoice}
            disabled={
              reason.trim() === "" ||
              voidInvoiceLoading ||
              (((billPaymentDetails?.payments ?? []).length === 0 ||
                (billPaymentDetails?.totalAmount ?? 0) === 0) &&
                voidTypeIndex === 1)
            }
          >
            {voidInvoiceLoading ? (
              <CircularProgress sx={{ color: Colors.ACCENT_GRAY_DISABLED }} />
            ) : (
              "Void Invoice"
            )}
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default VoidInvoiceModal;
