import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
// import { CustomerSearchMode } from '../../../enum/CustomerSearchMode';
import axios from "axios";
import { Colors, SEARCH_CUSTOMER } from "../../../configs";
import { format } from "date-fns";
import AddIcon from "@mui/icons-material/Add";

import { logoutAction } from "../../../redux/actions/authAction";
import { CustomButton, CustomTextBox, PosLayout } from "../../../components";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import TopRightButton from "../../../components/common/top-right-button/top-right-button";
import ConfirmDialog from "../../../components/atoms/confirm-box/confirm-box";
import { useTranslation } from "../../../util/translation-provider";
import { ICustomerResponse } from "../../../typings";
import { CartSliceAction } from "../../../redux/slice/cartSlice";
import useBarcodeScanner from "../../../hooks/useBarcodeScanner";
import LoaderModal from "../../../components/atoms/loader-modal/loader-modal";
import { ILocalStationCashier } from "../../../typings/interfaces/responses/cashierStationResponses";
import {
  setActiveStation,
  setActiveStationCashier,
} from "../../../redux/slice/cashierStationSlice";

const SearchResultCad = (props: { details: ICustomerResponse }) => {
  const dispatch = useAppDispatch();
  const handleOnItemClick = () => {
    props.details.id && localStorage.removeItem(props.details.id.toString());
    dispatch(CartSliceAction.setSelectedCustomer(props.details));
  };
  return (
    <Link
      to={`/pos/${props.details.id}`}
      onClick={handleOnItemClick}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Box
        sx={{
          background: Colors.WHITE,
          borderRadius: "0.285714286rem",
          padding: "1.07142857rem",
          mt: "1.42857143rem",
          boxShadow:
            "0 0.0714285714rem 1.42857143rem 0.0714285714rem rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography sx={{ fontSize: "1.42857143rem" }}>
          {props.details.firstName} {props.details.lastName} |{" "}
          {props.details.id}
        </Typography>
        <Typography sx={{ fontSize: "1.14rem" }}>
          {props.details.personalAddressLine1},{" "}
          {props.details.personalAddressLine2}, {props.details.personalCity},{" "}
          {props.details.personalState}, {props.details.personalZip}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "1.14rem" }}>
            {props.details.mobilePhone}
          </Typography>
          <Typography sx={{ fontSize: "1.14rem" }}>
            Member since:{" "}
            {props.details.createdTime
              ? format(new Date(props.details.createdTime), "yyyy-MM-dd")
              : "N/A"}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

const options = [
  { label: "Pickup", url: "/", requiredActiveCashier: true },
  { label: "Edit Invoice", url: "/", requiredActiveCashier: true },
  {
    label: "Reprint Invoice",
    url: "/pos/search-invoice",
    requiredActiveCashier: true,
  },
  {
    label: "Reprint Tag",
    url: "/pos/search-pintag",
    requiredActiveCashier: true,
  },
  { label: "Void Invoice", url: "/", requiredActiveCashier: true },
  { label: "Conveyor Input", url: "/pos/convayor-input", requiredActiveCashier: true },
  {
    label: "Cash Register",
    url: "/pos/cash-register",
    requiredActiveCashier: false,
  },
  { label: "Reports", url: "/", requiredActiveCashier: true },
  { label: "Search", url: "/pos/search-general", requiredActiveCashier: true },
  { label: "Retail Sale", url: "/", requiredActiveCashier: true },
  { label: "Price List", url: "/pos/price-list", requiredActiveCashier: true },
  { label: "Pickup & Delivery", url: "/", requiredActiveCashier: true },
  {
    label: "Daily Snapshot",
    url: "/pos/daily-snapshot",
    requiredActiveCashier: true,
  },
  { label: "Settings", url: "/pos/settings", requiredActiveCashier: false },
];

const LobbyPage: FC = () => {
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchResults, setSearchResuts] = useState([]);
  const [query, setQuery] = useState("");
  const [debouncedText, setDebouncedText] = useState(query);
  const dispatch = useAppDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const translations = useTranslation();
  const navigate = useNavigate();
  const [showBill, setShowBill] = useState(false);
  const code = useBarcodeScanner();
  const [barCode, setBarCode] = useState("");

  const { activeStationCashier } = useAppSelector(
    (state) => state.cashierStation
  );

  useEffect(() => {
    if (!activeStationCashier) {
      const localCashierSession: ILocalStationCashier | null = JSON.parse(
        localStorage.getItem("activeCashierSession") || "null"
      );
      if (localCashierSession) {
        dispatch(setActiveStationCashier(localCashierSession));
      }
    }
  }, [activeStationCashier]);

  useEffect(() => {
    if (barCode) {
      setShowBill(true);
    }
  }, [barCode]);

  useEffect(() => {
    if (code) {
      let extractedCode = code.split("000");
      const finalCode = extractedCode.length > 0 ? extractedCode[1] : "";
      setBarCode(finalCode);
    }
  }, [code]);

  const handleOnCancelClick = () => {
    setShowBill(false);
  };

  const handleOpenConfirm = (openState: boolean) => {
    setConfirmOpen(openState);
  };

  useEffect(() => {
    dispatch(CartSliceAction.clearSession());
  }, [])

  const handleConfirm = () => {
    handleOnLogoutClick();
    handleOpenConfirm(false);
  };

  const onClickMenuItem = (menuItem: string) => {
    switch (menuItem) {
      case "Bags to Rack":
        navigate("/pos/bag-rack-register");
    }
  };

  // Update debouncedText after a delay
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedText(query);
    }, 800); // Adjust this delay as needed

    return () => {
      clearTimeout(timerId);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedText.length > 0) {
      searchCustomers();
    } else {
      setSearchResuts([]);
    }
  }, [debouncedText]);

  const searchCustomers = () => {
    setSearchInProgress(true);
    axios.get(SEARCH_CUSTOMER(debouncedText)).then(
      (res) => {
        res.data.body ? setSearchResuts(res.data.body) : setSearchResuts([]);
        setSearchInProgress(false);
      },
      (err) => {
        setSearchInProgress(false);
      }
    );
  };

  const onChangeQuery = (data: string) => {
    setQuery(data);
  };

  const handleOnLogoutClick = () => {
    dispatch(logoutAction());
  };

  const handleOnCreateCustomerClick = () => {
    navigate("/pos/customer/create");
  };

  return (
    <PosLayout>
      <Grid
        sx={{
          display: "flex",
          height: "100%",
        }}
      >
        <TopRightButton
          variation="Lock"
          onClick={() => handleOpenConfirm(true)}
        />

        <ConfirmDialog
          open={confirmOpen}
          title="Confirmation"
          message="Are you sure you want to proceed?"
          onCancel={() => handleOpenConfirm(false)}
          onConfirm={handleConfirm}
        />
        <LoaderModal
          onClose={handleOnCancelClick}
          open={showBill}
          billId={barCode || ""}
        />
        <Grid
          flex={7}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "2.85714286rem",
                lineHeight: "3.35714286rem",
                color: Colors.TEXT_GRAY_DARK,
                fontWeight: "700",
              }}
            >
              {translations.companyName}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.71428571rem",
                lineHeight: "1.92857143rem",
                color: Colors.TEXT_GRAY_DARK,
              }}
            >
              Main Lobby
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                width: "56%",
                height: "86%",
                overflow: "auto",
                position: "absolute",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
                borderRight: "0.142857143rem solid",
                borderRightColor: Colors.ACCENT_GRAY,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.785714286rem",
                  flexWrap: "wrap",
                  pt: "1.42857143rem",
                  // ml: "-0.285714286rem",
                  pr: "1.42857143rem",
                }}
              >
                {options?.map((item) => (
                  <Link
                    to={item.url}
                    style={{
                      textDecoration: "none",
                      maxWidth: "calc((100% - (2*0.785714286rem))/3)",
                      minWidth: "calc((100% - (2*0.785714286rem))/3)",
                      flex: 1,
                      maxHeight: "12.1714286rem",
                      alignSelf: "stretch",
                      pointerEvents:
                        item.requiredActiveCashier &&
                        activeStationCashier?.status !== "open"
                          ? "none"
                          : "auto",
                    }}
                  >
                    <CustomButton
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: "100%",
                        boxShadow: "none",
                        // width: "18.1190476rem",
                        borderRadius: "0.71rem",
                        fontSize: "2rem",
                        textTransform: "capitalize",
                        lineHeight: "2.34357143rem",
                        fontWeight: "bold",
                      }}
                      disabled={
                        item.requiredActiveCashier &&
                        activeStationCashier?.status !== "open"
                      }
                    >
                      {item.label}
                    </CustomButton>
                  </Link>
                ))}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid flex={5} sx={{ pl: "1.42857143rem", pt: "5.35714286rem" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: Colors.BACKGROUND_GRAY,
              borderRadius: "0.71rem",
            }}
          >
            <Box
              sx={{
                px: "1.71428571rem",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  pt: "1.71428571rem",
                  width: "100%",
                }}
              >
                <CustomButton
                  variant="contained"
                  onClick={handleOnCreateCustomerClick}
                  sx={{
                    width: "4.28571429rem !important",
                    minWidth: "4.28571429rem",
                    maxWidth: "4.28571429rem",
                    height: "4.28571429rem",
                    borderRadius: "1.07142857rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexShrink: 0,
                    padding: "0",
                  }}
                  disabled={activeStationCashier?.status !== "open"}
                >
                  <AddIcon />
                </CustomButton>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{
                    fontSize: "2.28571429rem",
                    lineHeight: "2.64285714rem",
                    color: Colors.TEXT_GRAY_DARK,
                  }}
                >
                  Customer Search
                </Typography>
              </Box>
              <Box sx={{ my: "1.42857143rem" }}>
                <CustomTextBox
                  styles={{
                    borderRadius: "1.07142857rem",
                    height: "4rem",
                    backgroundColor:
                      activeStationCashier?.status !== "open"
                        ? Colors.BACKSHADE_GRAY
                        : Colors.BACKGROUND_GRAY,
                  }}
                  sx={{ width: "100%" }}
                  onChange={(e) => onChangeQuery(e.target.value)}
                  placeholder="Enter Name, Phone Number, Customer ID"
                  disabled={activeStationCashier?.status !== "open"}
                />
              </Box>
              <Box sx={{ flex: 1, position: "relative" }}>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    overflow: "auto",
                    position: "absolute",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    "-ms-overflow-style": "none",
                    "scrollbar-width": "none",
                  }}
                >
                  {searchInProgress && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  )}
                  {searchResults?.map((result, index) => {
                    return <SearchResultCad details={result} key={index} />;
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PosLayout>
  );
};

export { LobbyPage };
